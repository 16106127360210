import * as React from 'react';
import {Link} from "react-router-dom";
import {translate} from "react-translate";
import {Header, Icon} from 'semantic-ui-react';

import {dateAndTimeSecFormat} from "../../utilsNew/date";

const MainHeader = ({title, id, abbrev, timestamp, date, edit, t}) => (
    <>
        <Header as="h2" icon={true} style={{marginBottom: 0}} className='MainHeader-h2'>
            {edit ? <Icon name="sitemap"/> : <Link to={'/public/tezaurus/visualization/' + id}><Icon name="sitemap"/></Link>}
            {title}{(typeof abbrev === 'string' ? abbrev === 'true' : abbrev) && ' (' + t('ABBREV') + ')'}
        </Header>
        <Header as="h6" style={{marginTop: 0}}>
            {id ? ('ID: ' + id) : t('NEW_TERM')}
            {!!timestamp && (date ? ` | ${t('DATE')}: ${dateAndTimeSecFormat(date)}` : ` | timestamp: ${timestamp}`)}
        </Header>
    </>
);

export default translate('Term')(MainHeader);
