import * as React from 'react';
import {translate} from 'react-translate';
import {Header, Icon, Segment, Divider, Grid} from 'semantic-ui-react';

import {dateFormat} from '../utilsNew/date';
import {confirmThesaurus, deleteThesaurus} from './_actionsCentral'

const approveChanges = async (id) => {
    await confirmThesaurus(id);
    window.location.reload();
};

const deleteTez = async (id) => {
    await deleteThesaurus(id);
    window.location.reload();
};

const HomepageTez = ({data, admin, t}) => (
    <Segment
        className={data.confirmed + 'Confirmed'}
        style={{ marginTop: '1px', marginBottom: '2em', position: 'relative' }}
    >
        <Header as='h1' className='homepageTez-header'>{data.name}</Header>
        {admin && (data.confirmed === 'true' ? (
          <span className='homepageTez-delete' onClick={() => deleteTez(data.tid)}><Icon name='trash'/></span>
        ) : (
          <span className='homepageTez-approve' onClick={() => approveChanges(data.tid)}><Icon name='check'/></span>
        ))}
        <Divider horizontal>{data.organization}</Divider>
        <Grid centered columns={2} className='homepageTez-grid'>
            <Grid.Column>
                <span>{t('TIME_REGISTER')}</span>
                <p>{dateFormat(data.time_register)}</p>
                <span>{t('TERMS_COUNT')}</span>
                <p>{data.terms_count}</p>
            </Grid.Column>

            <Grid.Column>
                <span>{t('TIME_UPDATE')}</span>
                <p>{dateFormat(data.time_update)}</p>
                <span>{t('URL')}</span>
                <p><a target='_blank' rel='noopener noreferrer' href={data.url}>{data.url}</a></p>
            </Grid.Column>
        </Grid>
        <br/>
        {admin ? (
            <>
                <Header as='h4'>{t('CONTACT')}</Header>
                <Grid centered columns={2} className='homepageTez-grid'>
                    <Grid.Column>
                        <span>TID</span>
                        <p>{data.tid}</p>
                        <span>{t('CONTACT_PHONE')}</span>
                        <p>{data.contact_phone}</p>
                    </Grid.Column>

                    <Grid.Column>
                        <span>{t('CONTACT_PERSON')}</span>
                        <p>{data.contact_person}</p>
                        <span>{t('CONTACT_EMAIL')}</span>
                        <p>{data.contact_email}</p>
                    </Grid.Column>
                </Grid>
                <br/>
            </>
        ) : <></>}
        <Header as='h4'>{t('DESCRIPTION')}</Header>
        <p style={{ textAlign: 'justify', padding: '0 .75em' }}>
            {data.description}
        </p>
    </Segment>
);

export default translate('HomepageTezC')(HomepageTez);
