export const ROLES = [{ key: 'admin', name: 'Administrátor' }, { key: 'user', name: 'Klient' }];

export const CENTRAL = parseInt(process.env.REACT_APP_CENTRAL);

export const ROOT = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

export const ID_PART_OF_TITLE_DELIMITER = '\n$ID_PART_OF_TITLE_DELIMITER$';

export const BREAKPOINTS = {
  mobileMax: 767,

  tabletMin: 768,
  tabletMax: 991,

  desktopMin: 992,
  desktopMax: 1200,

  largeMin: 1201
};
