import * as React from 'react';
import {translate} from "react-translate";
import {Header, Icon, Segment} from 'semantic-ui-react';
import {dateAndTimeFormat} from "../../utilsNew/date";

const Meta = ({entry, t}) => (
    <>
        <Header as='h3' attached='top'>
            <Icon name='file alternate outline'/>{t('META')}
        </Header>
        <Segment attached='bottom'>
            {entry.meta.author && (
                <p><b>{t('AUTHOR')}:</b> {entry.meta.author.$}</p>
            )}
            {entry.meta.creation_time && (
                <p><b>{t('CREATION_TIME')}:</b> {dateAndTimeFormat(entry.meta.creation_time.$)}</p>
            )}
            {entry.meta.published_time && (
                <p><b>{t('PUBLISHED_TIME')}:</b> {dateAndTimeFormat(entry.meta.published_time.$)}</p>
            )}
            {entry.meta.update_time && (
                <p><b>{t('UPDATE_TIME')}:</b> {dateAndTimeFormat(entry.meta.update_time.$)}</p>
            )}
        </Segment>
    </>
);

export default translate('Term')(Meta);
