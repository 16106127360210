import "whatwg-fetch"

export const authorize = (rights, roles) => !!(roles && roles.length && rights && rights.length && (
  roles.filter(v => rights.includes(v)).length > 0
));

export const removeFromArr = (arr, what) => {
    let index = arr.indexOf(what);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
};

export const customFetch = async (url, options) => {
    const response = await fetch(url, options).then(r => r.json());
    if (response.error) alert("ERROR: " + response.msg);
    else return response;
};
