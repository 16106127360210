import * as React from 'react';
import {translate} from "react-translate";

import TezInfoForm from '../../modulesUniversal/TezInfoForm';
import BasicSettingsPage from "./BasicSettingsPage";
import Context from "../../Context";
import {save_settings, send_settings} from "../_actions";
import {handleActionWithSetSaved} from "../../components/enhanced/EnhancedSubmitButton";

const Metadata = ({history, t}) => {
  const {settings, refresh} = React.useContext(Context);

  const [initialValues, setInitialValues] = React.useState(false);
  const [saved, setSaved] = React.useState(false);

  React.useEffect(() => {
    setInitialValues(settings.meta);
  }, [settings]);

  const handleSubmit = async (data) => {
    const newSettings = JSON.parse(JSON.stringify(settings));
    newSettings.meta = data;
    handleActionWithSetSaved(setSaved, () => save_settings(newSettings)).then(refresh);
  };

  const handleSend = async (event, data) => {
    event.preventDefault();
    handleActionWithSetSaved(setSaved, () => send_settings(data));
  };

  return (
    <BasicSettingsPage history={history} current={1} saved={saved}>
      <p>{t('DESCRIPTION')}</p>
      {initialValues ? (
        <TezInfoForm
          handleSubmit={handleSubmit}
          handleSend={handleSend}
          initialValues={initialValues}
          saved={saved}
        />
      ) : (
        <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
      )}
    </BasicSettingsPage>
  );
};

export default translate('Settings')(Metadata);
