import * as React from 'react';
import {translate} from "react-translate";
import {Button, Modal} from "semantic-ui-react";

const EnhancedModal = ({what, handleClick, modal, setModal, t}) => (
  modal ? (
    <Modal size={'small'} open={!!modal} onClose={() => setModal(false)}>
      <Modal.Header>{t('MODAL_HEADER')}</Modal.Header>
      <Modal.Content>
        {modal === true ? (
          <div style={{textAlign: 'center'}}>
            <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
          </div>
        ) : !modal.data ? (
          t('ERROR')
        ) : !modal.data.length ? (
          t('NO_RESULTS')
        ) : (
          modal.data.map((e, i) => (
            <p className='trans-hint' key={i + e[what]}>
              {i + 1}) <span key={e[what]} onClick={() => handleClick(e.id ? e.id : modal.id, e[what], e['entry-id'])}>
                {e[what]}{e['entry-id'] && <span style={{fontWeight: 'bold', opacity: .4, fontStyle: 'italic'}}> ID: {e['entry-id']}</span>}
              </span>
            </p>
          ))
        )}
      </Modal.Content>
      <Modal.Actions><Button negative onClick={() => setModal(false)}>{t('CLOSE')}</Button></Modal.Actions>
    </Modal>
  ) : <></>
);

export default translate('Term')(EnhancedModal);
