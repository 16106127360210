import * as React from 'react';
import {translate} from "react-translate";
import {useDropzone} from "react-dropzone";
import {Segment, Grid, Divider, Button, Message} from 'semantic-ui-react';

import BasicSettingsPage from "./BasicSettingsPage";
import EnhancedColorPicker from '../../components/enhanced/EnhancedColorPicker'
import {save_design} from "../_actions";
import {COLORS} from "../../constants/colors.js"

const data = {colors: {}};

const ThemeCreator = ({history, t}) => {
  const [openedPicker, setOpenedPicker] = React.useState(0);
  const [colors, setColors] = React.useState(COLORS);
  const [fileName, setFileName] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(undefined);

  const generate = async () => {
    setLoading(true)
    Object.keys(colors).forEach(key => data.colors[key] = colors[key].color);
    const response = await save_design(data);
    if (response.ok) setMessage({ok: true, msg: t('MSG_OK')})
    else setMessage({ok: false, msg: t('MSG_ERROR')})
    setLoading(false)
    setTimeout(() => setMessage(undefined), 10000)
  };

  const DropZone = () => {
    const onDrop = React.useCallback(acceptedFiles => {
      setFileName(null);
      data.file_meta = acceptedFiles[0];

      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onabort = () => alert('file reading was aborted');
      reader.onerror = () => alert('file reading has failed');
      reader.onload = () => {
        data.file = reader.result;
        setFileName(data.file_meta.path);
      };
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
      <div {...getRootProps()} className='theDropZone'>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{t('DROP')}</p>
        ) : (
          <p>{t('DRAG')}</p>
        )}
      </div>
    );
  };

  return (
    <BasicSettingsPage history={history} current={2}>
      {colors ? (
        <Segment id='color-pickers'>
          <p style={{margin: '.25em 0 1.5em'}}>{t('DESCRIPTION_THEME')}</p>
          <Divider horizontal>{t('COLORS')}</Divider>
          <div style={{display: 'inline-block'}}>
            {Object.keys(colors).map((key, i) => (
              <Grid.Column className={openedPicker === (i + 1) ? 'openedPicker' : ''}>
                <EnhancedColorPicker
                  openedPicker={openedPicker} setOpenedPicker={setOpenedPicker} num={(i + 1)}
                  init={colors[key].color} text={t(colors[key].text)}
                  fn={c => {
                    if (colors[key].fn) {
                      if (colors[key].important) {
                        colors[key].fn(document).map(e => e.setAttribute('style', colors[key].what + ': ' + c + ' !important'));
                      } else {
                        colors[key].fn(document).map(e => e.style[colors[key].what] = c);
                      }
                    }
                    setColors({...colors, [key]: {...colors[key], color: c}})
                  }}/>
              </Grid.Column>
            ))}
          </div>
          <br/><br/>
          <Divider horizontal>{t('LOGO')}</Divider>
          <div>
            {fileName ? (
              <div>
                <br/>
                {fileName} <Button circular icon='close' onClick={() => setFileName()} negative size='mini'/>
                <br/>
                <br/>
                <br/>
              </div>
            ) : fileName === null ? (
              <div>
                <br/>
                <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
                <br/>
                <br/>
                <br/>
              </div>
            ) : (
              <DropZone/>
            )}
            <Button loading={loading} disabled={loading} onClick={generate}>{t('GENERATE')}</Button>
          </div>
        </Segment>
      ) : (
        <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
      )}

      {message && (
        <div style={{position: 'absolute', top: '25vh'}}>
          <div style={{position: 'fixed', width: '100%', left: 0}}>
            <Message floating positive={message.ok} negative={!message.ok} compact>{message.msg}</Message>
          </div>
        </div>
      )}
    </BasicSettingsPage>
  )
};

export default translate('Settings')(ThemeCreator);
