import variables from '../styles/_variablesExport.scss'

export const COLORS = {
  mainColor: {
    color: variables.mainColor,
    text: 'TEXT_COLOR',
    fn: () => [document.getElementById('mainBody')],
    what: 'color',
  },
  mainBckg: {
    color: variables.mainBckg,
    text: 'BODY_BACKGROUND',
    fn: () => [document.getElementById('mainBody')],
    what: 'background',
  },
  segmentBckg: {
    color: variables.segmentBckg,
    text: 'SEGMENT_BACKGROUND',
    fn: () => [document.getElementsByClassName('segment')[2]],
    what: 'background',
  },
  menuColor: {
    color: variables.menuColor,
    text: 'BAR_COLOR',
    fn: () => [document.getElementById('appBar')],
    what: 'color',
  },
  menuBckg: {
    color: variables.menuBckg,
    text: 'BAR_BACKGROUND',
    fn: () => [document.getElementById('appBar')],
    what: 'background',
  },
  blueTreeNode: {
    color: variables.blueTreeNode,
    text: 'TREE_NODE',
  },
  visColor: {
    color: variables.visColor,
    text: 'VISUALIZATION_COLOR',
  },
  positive: {
    color: variables.positive,
    text: 'POSITIVE',
  },
  focusColor: {
    color: variables.focusColor,
    text: 'FOCUS_COLOR',
    fn: () => [document.getElementsByClassName('red')[0], document.getElementsByClassName('active')[0]],
    what: 'background-color',
    important: true,
  },
  error: {
    color: variables.error,
    text: 'ERROR_COLOR',
  },
};
