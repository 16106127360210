import * as React from 'react';
import {translate} from "react-translate";
import {Flag, Header, Icon, Label, Segment, Select} from 'semantic-ui-react';
import {ArrayField, Text, TextArea} from "informed";

import ContextUser from "../../ContextUser";
import TextWithDropdown from "../../components/enhanced/TextWithDropdown";
import EnhancedImg from "../../components/enhanced/EnhancedImg";
import ContextInitialValues from "../../ContextInitialValues";
import {SelectH, TextH, TextAreaH} from "../../components/informedHighlight";

export const numberOfFields = 11;
const addNewDef = (add) => {
  for (let i = 0; i < numberOfFields; i++) add();
};

const Definitions = ({formApi, edit, entry, settings, newTerm, optionsLang, changed = [], t}) => {
  const {loggedUser} = React.useContext(ContextUser);
  const {defaultLang, langsDefHandlers} = React.useContext(ContextInitialValues);
  const {handleSelectDefinitions: handleSelect} = langsDefHandlers;

  const loggedUserObor = loggedUser && loggedUser.roles && loggedUser.roles.filter(e => e.indexOf('obor_redaktor_') >= 0).map(e => e.split('obor_redaktor_')[1])[0];

  return (
    <>
      <Header as='h3' attached='top'>
        <Icon name='comment alternate'/>{t('DEFINITION')}
      </Header>

      {!edit ? (
        <Segment attached='bottom'>
          <ol id='defs'>
            {entry.defs.def.map((e, i) => (
              <li key={'def' + i}>
                <p>
                  {e['@lang'] && <Flag style={{margin: '0 4px 0 2px'}} title={e['@lang']} name={e['@lang']}/>} <span dangerouslySetInnerHTML={{__html: (e.html ? e.html.$ : e.text ? e.text.$ : e.$)}}/>
                </p>
                {e.zdroj && e.zdroj.$ && <p><b>{t('SOURCE')}:</b> {e.zdroj.$}</p>}
                {e.law && e.law.$ && <p><b>{t('LAW')}</b>: {e.law.$}</p>}
                {e.math_base64 && e.math_base64.$ && (
                  <p>
                    <b>{t('MATH')}</b>: <EnhancedImg src={'data:image/jpeg;base64, ' + e.math_base64.$}
                                                     alt={e.math && e.math.$}/>
                  </p>
                )}
                {e.table_base64 && e.table_base64.$ && (
                  <p>
                    <b>{t('TABLE')}</b>: <EnhancedImg src={'data:image/jpeg;base64, ' + e.table_base64.$}
                                                      alt={e.table && e.table.$}/>
                  </p>
                )}
                {e['@obor'] && (
                  <p><b>{t('OBOR')}</b>: <Label horizontal style={
                    {minWidth: 'unset', padding: '0.2em .4em', margin: 0, color: 'white', background: '#' + e['@obor_color']}
                  }>{e['@obor_mark']}</Label> {e['@obor']}</p>
                )}
                {e['type'] && e['type'].$ && <p><b>{t('TYPE')}:</b> {e.type.$}</p>}
                {e['druh'] && e['druh'].$ && <p><b>{t('DRUH')}:</b> {e.druh.$}</p>}
                {e.moreinfo && e.moreinfo.$ && <p><b>{t('MORE_INFO')}</b>: {e.moreinfo.$}</p>}
                {e.private && e.private.$ && loggedUser && loggedUser.roles && loggedUser.roles.length > 1 && <p><b>{t('PRIVATE_INFO')}</b>: {e.private.$}</p>}
              </li>
            ))}
          </ol>
        </Segment>
      ) : (
        <Segment attached='bottom' className='termForm-meaning'>
          <ol id='defs'>
            {!newTerm ? (entry.defs && entry.defs.def && entry.defs.def.length && (
              entry.defs.def.map((e, i) => (
                <li key={'def' + i} className={changed[i] === null && 'changedDiff-NewBlock'}>
                  <div className="ui form rqField defsLangs">
                    <TextH field={'orderNumber' + i}/>
                    <SelectH
                      compact options={optionsLang}
                      defaultValue={e['@lang'] ? e['@lang'] : defaultLang}
                      onChange={(e, option) => handleSelect({option, i, newTerm: false})}
                    />
                    {changed[i] && changed[i]['@lang'] && <p className='changedDiff'>{changed[i]['@lang']}</p>}
                  </div>
                  <div className="ui form rqField defsField-largerTextArea"><TextAreaH field={'def$' + i}/></div>
                  {changed[i] && changed[i].html && <p className='changedDiff'>{changed[i].html.$}</p>}
                  <div className="ui form rqField"><b>{t('SOURCE')}:</b> <TextAreaH field={'defSource' + i}/></div>
                  {changed[i] && changed[i].zdroj && <p className='changedDiff'>{changed[i].zdroj.$}</p>}
                  <div className="ui form"><b>{t('LAW')}</b>: <TextAreaH field={'defLaw' + i}/></div>
                  {changed[i] && changed[i].law && <p className='changedDiff'>{changed[i].law.$}</p>}
                  <div className="ui form"><b>{t('MATH')}</b>: <TextAreaH field={'defMath' + i}/></div>
                  {changed[i] && changed[i].math_base64 && <p className='changedDiff'>{changed[i].math_base64.$}</p>}
                  <div className="ui form defsField-largerTextArea"><b>{t('TABLE')}</b>: <TextAreaH field={'defTable' + i}/></div>
                  {changed[i] && changed[i].table_base64 && <p className='changedDiff'>{changed[i].table_base64.$}</p>}
                  <div className="ui form rqField"><b>{t('OBOR')}</b>:
                    <TextWithDropdown
                      options={settings.domainsFormattedText}
                      formApi={formApi}
                      field={'defObor' + i}
                      placeholder={t('OBOR')}
                      defaultValue={entry.defs.def[i]['@obor'] ? entry.defs.def[i]['@obor'].split('|')[0] : undefined}
                    />
                  </div>
                  {changed[i] && changed[i]['@obor'] && <p className='changedDiff'>{changed[i]['@obor']}</p>}
                  <div className="ui form"><b>{t('DRUH')}</b>:
                    <TextWithDropdown
                      options={settings.druhyFormatted}
                      formApi={formApi}
                      field={'defDruh' + i}
                      placeholder={t('DRUH')}
                      defaultValue={entry.defs.def[i].druh && entry.defs.def[i].druh.$}
                    />
                  </div>
                  {changed[i] && changed[i].druh && <p className='changedDiff'>{changed[i].druh.$}</p>}
                  <div className="ui form"><b>{t('TYPE')}</b>:
                    <TextWithDropdown
                      options={settings.typesFormatted}
                      formApi={formApi}
                      field={'defType' + i}
                      placeholder={t('TYPE')}
                      defaultValue={entry.defs.def[i].type && entry.defs.def[i].type.$}
                    />
                  </div>
                  {changed[i] && changed[i].type && <p className='changedDiff'>{changed[i].type.$}</p>}
                  <div className="ui form"><b>{t('MORE_INFO')}</b>: <TextAreaH field={'defMoreinfo' + i}/></div>
                  {changed[i] && changed[i].moreinfo && <p className='changedDiff'>{changed[i].moreinfo.$}</p>}
                  <div className="ui form"><b>{t('PRIVATE_INFO')}</b>: <TextAreaH field={'defPrivate' + i}/></div>
                  {changed[i] && changed[i].private && <p className='changedDiff'>{changed[i].private.$}</p>}
                </li>
              ))
            )) : (
              <li key={'def0'}>
                <div className="ui form rqField defsLangs">
                  <Text field='orderNumber0' initialValue={1} className={'hgltd'}/>
                  <Select
                    compact options={optionsLang}
                    defaultValue={defaultLang}
                    onChange={(e, option) => handleSelect({option, i: 0, newTerm: false})}
                    className={'hgltd'}
                  />
                </div>
                <div className="ui form rqField defsField-largerTextArea"> <TextArea field='def$0' className={'hgltd'}/></div>
                <div className="ui form rqField"><b>{t('SOURCE')}:</b> <TextArea field={'defSource0'} className={'hgltd'}/></div>
                <div className="ui form"><b>{t('LAW')}</b>: <TextArea field={'defLaw0'} className={'hgltd'}/></div>
                <div className="ui form"><b>{t('MATH')}</b>: <TextArea field={'defMath0'} className={'hgltd'}/></div>
                <div className="ui form defsField-largerTextArea"><b>{t('TABLE')}</b>: <TextArea field={'defTable0'} className={'hgltd'}/></div>
                <div className="ui form rqField"><b>{t('OBOR')}</b>:
                  <TextWithDropdown
                    options={settings.domainsFormattedText}
                    formApi={formApi}
                    field={'defObor0'}
                    placeholder={t('OBOR')}
                    className={'hgltd'}
                    defaultValue={loggedUserObor}
                  />
                </div>
                <div className="ui form"><b>{t('DRUH')}</b>:
                  <TextWithDropdown
                    options={settings.druhyFormatted}
                    formApi={formApi}
                    field={'defDruh0'}
                    placeholder={t('DRUH')}
                    className={'hgltd'}
                  />
                </div>
                <div className="ui form"><b>{t('TYPE')}</b>:
                  <TextWithDropdown
                    options={settings.typesFormatted}
                    formApi={formApi}
                    field={'defType0'}
                    placeholder={t('TYPE')}
                    className={'hgltd'}
                  />
                </div>
                <div className="ui form"><b>{t('MORE_INFO')}</b>: <TextArea field='defMoreinfo0' className={'hgltd'}/></div>
                <div className="ui form"><b>{t('PRIVATE_INFO')}</b>: <TextArea field='defPrivate0' className={'hgltd'}/></div>
              </li>
            )}
            <ArrayField field='defNew'>
              {({ add, fields }) => (
                <>
                  {fields.map((e, i) => (
                    (i % numberOfFields === 0) && <li key={e.key}>
                      <div className="ui form rqField defsLangs">
                        <Text
                          field={fields[i + 10].field}
                          initialValue={(i / numberOfFields) + 1 + ((entry && entry.defs && entry.defs.def) ? entry.defs.def.length : 0)}
                          className={'hgltd'}
                        />
                        <Select
                          compact options={optionsLang}
                          defaultValue={e['@lang'] ? e['@lang'] : defaultLang}
                          onChange={(e, option) => handleSelect({option, i: (i / numberOfFields), newTerm: true})}
                          className={'hgltd'}
                        />
                      </div>
                      <div className="ui form rqField defsField-largerTextArea"> <TextArea field={fields[i].field} className={'hgltd'}/></div>
                      <div className="ui form rqField"><b>{t('SOURCE')}:</b> <TextArea field={fields[i + 1].field} className={'hgltd'}/></div>
                      <div className="ui form"><b>{t('LAW')}</b>: <TextArea field={fields[i + 2].field} className={'hgltd'}/></div>
                      <div className="ui form"><b>{t('MATH')}</b>: <TextArea field={fields[i + 3].field} className={'hgltd'}/></div>
                      <div className="ui form"><b>{t('TABLE')}</b>: <TextArea field={fields[i + 4].field} className={'hgltd'}/></div>
                      <div className="ui form rqField"><b>{t('OBOR')}</b>:
                        <TextWithDropdown
                          options={settings.domainsFormattedText}
                          formApi={formApi}
                          field={fields[i + 5].field}
                          placeholder={t('OBOR')}
                          className={'hgltd'}
                          defaultValue={loggedUserObor}
                        />
                      </div>
                      <div className="ui form"><b>{t('DRUH')}</b>:
                        <TextWithDropdown
                          options={settings.druhyFormatted}
                          formApi={formApi}
                          field={fields[i + 6].field}
                          placeholder={t('DRUH')}
                          className={'hgltd'}
                        />
                      </div>
                      <div className="ui form"><b>{t('TYPE')}</b>:
                        <TextWithDropdown
                          options={settings.typesFormatted}
                          formApi={formApi}
                          field={fields[i + 7].field}
                          placeholder={t('TYPE')}
                          className={'hgltd'}
                        />
                      </div>
                      <div className="ui form"><b>{t('MORE_INFO')}</b>: <TextArea field={fields[i + 8].field} className={'hgltd'}/></div>
                      <div className="ui form"><b>{t('PRIVATE_INFO')}</b>: <TextArea field={fields[i + 9].field} className={'hgltd'}/></div>
                    </li>
                  ))}

                  <Icon onClick={() => addNewDef(add)} name='add circle' className='termForm-addCircle' />
                </>
              )}
            </ArrayField>
          </ol>
        </Segment>
      )}
    </>
  );
};

export default translate('Term')(Definitions);
