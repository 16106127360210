import * as React from 'react'
import {Container, Segment} from 'semantic-ui-react'
import {translate} from 'react-translate'

import Context from '../Context'
import {get_stats} from './_actions'

const StaticInfo = ({lang, t}) => {
  const {settings} = React.useContext(Context)

  const [stats, setStats] = React.useState(undefined)

  React.useEffect(() => {
    get_stats().then(data => setStats(data))
  }, [])

  return (
    <Segment
      textAlign="center"
      style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
      vertical={true}
    >
      <Container text={true}>
        {settings && settings.meta ? (
          <span>{settings.meta['info' + lang]}</span>
        ) : (
          <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
        )}
        <div id='StaticInfo-stats'>
          {stats ? (
            <table style={{margin: 'auto', textAlign: 'left'}}>
              <tbody>
              <tr>
                <td>{t('TOTAL')}:</td>
                <td><b>{stats.entries}</b></td>
              </tr>
              <tr>
                <td>{t('TOTAL_BY_LANG')}:</td>
                <td><table><tbody>{stats.lang && stats.lang.map(e => (
                  <tr>
                    <td>{e.lang.toUpperCase()}</td>
                    <td style={{textAlign: 'right'}}><b>{e.count}</b></td>
                  </tr>
                ))}</tbody></table></td>
              </tr>
              <tr>
                <td>{t('TOTAL_DEFS')}:</td>
                <td><b>{stats.defs}</b></td>
              </tr>
              <tr>
                <td>{t('TOTAL_BY_DOM')}:</td>
                <td><table><tbody>{stats.doms && stats.doms.map(e => (
                  <tr>
                    <td>{e.dom}</td>
                    <td style={{textAlign: 'right'}}><b>{e.count}</b></td>
                  </tr>
                ))}</tbody></table></td>
              </tr>
              </tbody>
            </table>
          ) : (
            <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
          )}
        </div>
      </Container>
    </Segment>
  )
}

export default translate('StaticInfo')(StaticInfo)
