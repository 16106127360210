import * as React from 'react';
import {translate} from 'react-translate';
import {Header} from 'semantic-ui-react';

import EnhancedSearchWithModal from "../../components/enhanced/EnhancedSearchWithModal";

const TermLinksPaths = ({t, type, what, edit, entry, newTerm, title, deleted, setDeleted, newSees, setNewSees, onClickNavigate}) => {
  const whats = what + 's';

  const onClickRemoveSeeText = (i) => {
    if (edit) {
      let tmp = JSON.parse(JSON.stringify(deleted));
      tmp[whats][i] = true;
      setDeleted(tmp);
    }
  };

  const newSeesRemove = (i) => {
    let tmp = JSON.parse(JSON.stringify(newSees));
    tmp[what].splice(i, 1);
    setNewSees(tmp);
  };

  return (
    <>
      {(edit || (entry[whats] && entry[whats][what])) && <>
        <Header as='h3'>
          {t(type)}
        </Header>
        {!newTerm && entry[whats] && entry[whats][what] && entry[whats][what].map((e, i) => (
          e['@entry-id'] ? (
            <a href={'/public/tezaurus/term/' + e['@entry-id']} key={e['@entry-id'] + type + '-' + i}
               onClick={(v) => onClickNavigate(v, e['@entry-id'], i, whats)}
               style={{display: (edit && deleted[whats][i]) ? 'none' : false}}>{e['$']}</a>
          ) : (
            <span key={'noid' + type + '-' + i} onClick={() => onClickRemoveSeeText(i, whats)} className='newSees-node'
                  style={{display: (edit && deleted[whats][i]) ? 'none' : false}}>{e['$']}</span>
          )
        ))}
      </>}

      {edit && (
        <>
          <Header as='h5'>{t('ADD_NEW_SEE')}</Header>
          <EnhancedSearchWithModal title={title} what={what} newSees={newSees} setNewSees={setNewSees}/>

          {newSees[what] && newSees[what].length > 0 && (
            <div className='newSees-willBeAdded'>
              <p>{t('WILL_BE_ADDED')}</p>
              {newSees[what].map((e, i) => (
                <p key={what + e.$ + i} onClick={() => newSeesRemove(i)}>
                  {e.$} {e['@entry-id'] && <span>ID: {e['@entry-id']}</span>}
                </p>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default translate('Term')(TermLinksPaths);
