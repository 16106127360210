import * as React from 'react';
import {translate} from "react-translate";

import BasicSettingsPage from './BasicSettingsPage'

const Settings = ({history, t}) => (
    <BasicSettingsPage
        history={history}
    />
);

export default translate('Settings')(Settings);
