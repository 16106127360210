import * as React from 'react';
import {translate} from "react-translate";
import {Container, Header, Icon, Segment, List, Button, Input, Popup, Select} from 'semantic-ui-react';
import {Form, Text} from "informed";
import {DatesRangeInput} from 'semantic-ui-calendar-react';

import {searchWithFilter} from "./_actionsSearch";
import EnhancedDropdown from "../../components/enhanced/EnhancedDropdown";
import Context from "../../Context";
import ContextUser from "../../ContextUser";
import scrollToAnim from "../../utilsNew/scrollToAnim";
import LanguageSelect from "../../components/enhanced/LanguageSelect";
import {authorize, removeFromArr} from "../../utilsNew";
import History from "../../history";

const SearchResults = ({t}) => {
    const {settings} = React.useContext(Context);
    const {loggedUser} = React.useContext(ContextUser);

    const [lastSearchTerm, setLastSearchTerm] = React.useState('');
    const [data, setData] = React.useState(0);
    const [domains, setDomains] = React.useState([]);
    const [author, setAuthor] = React.useState('');
    const [state, setState] = React.useState('');
    const [types, setTypes] = React.useState([]);
    const [kinds, setKinds] = React.useState([]);
    const [datesRange, setDatesRange] = React.useState('');
    const [datesChange, setDatesChange] = React.useState('');
    const [short, setShort] = React.useState('');
    const [currentLang, setCurrentLang] = React.useState('cz');
    const [selectedForExport, setSelectedForExport] = React.useState([]);

    let exportType = '';

    const exportWithFilter = (type) => {
        exportType = type;
        document.getElementById('filter-formSubmit').click();
    };

    const exportSelected = (type) => {
        window.open(encodeURI('/public/tecu?action=export_doc&type=' + type + '&data={"ids":[' + selectedForExport + ']}'), '_blank');
    };

    const getSearch = async (search) => {
        setData(undefined);
        setData(await searchWithFilter(search));
        setTimeout(() => scrollToAnim(822, 500), 10);
    };

    const handleSubmit = (search) => {
        let datesRangeParsed = ['', ''];
        let datesChangeParsed = ['', ''];
        let splitDate = '';
        (datesRange.split(' - ').forEach((e, i) => {
            splitDate = e.split('. ');
            if (splitDate && splitDate[0] !== '') datesRangeParsed[i] = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
        }));
        (datesChange.split(' - ').forEach((e, i) => {
            splitDate = e.split('. ');
            if (splitDate && splitDate[0] !== '') datesChangeParsed[i] = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
        }));
        const searchQuery = {};

        searchQuery.lang = currentLang;
        setLastSearchTerm(search.search);
        if (search.search) searchQuery.search = search.search;
        if (search.definition) searchQuery.definice = search.definition;
        if (author !== '') searchQuery.author = author;
        if (state && state !== '') searchQuery.stav = state;
        if (datesRangeParsed[0]) searchQuery.datum_zmeny_od = datesRangeParsed[0];
        if (datesRangeParsed[1]) searchQuery.datum_zmeny_do = datesRangeParsed[1];
        if (datesChangeParsed[0]) searchQuery.datum_zalozeni_od = datesChangeParsed[0];
        if (datesChangeParsed[1]) searchQuery.datum_zalozeni_do = datesChangeParsed[1];
        if (domains && domains.length) searchQuery.domain = domains;
        if (types && types.length) searchQuery.typ = types;
        if (kinds && kinds.length) searchQuery.druh = kinds;
        if (short !== '') searchQuery.zkratka = short;

        if (exportType === '') {
            getSearch(searchQuery);
        } else {
            window.open(encodeURI('/public/tecu?action=export_doc&type=' + exportType + '&data=' + JSON.stringify(searchQuery)), '_blank');
            exportType = '';
        }
    };

    const toggleSelected = id => {
        const tmpArr = [...selectedForExport];
        if (tmpArr.indexOf(id) >= 0) {
          tmpArr.splice(tmpArr.indexOf(id), 1);
          setSelectedForExport(tmpArr)
        } else {
          tmpArr.push(id);
          setSelectedForExport(tmpArr);
        }
    };

    const FilterExportButtons = ({onClickCallback, id}) => (
      <div id={id}>
          <Popup content={t('PDF')}
                 trigger={
                     <Icon name='circular file pdf' onClick={() => onClickCallback('pdf')}/>
                 }/>
          <Popup content={t('PDF_SHORT')}
                 trigger={
                     <Icon name='circular file pdf outline' onClick={() => onClickCallback('pdf2')}/>
                 }/>
          <Popup content={t('RTF')}
                 trigger={
                     <Icon name='circular file word' onClick={() => onClickCallback('rtf')}/>
                 }/>
          <Popup content={t('RTF_SHORT')}
                 trigger={
                     <Icon name='circular file word outline' onClick={() => onClickCallback('rtf2')}/>
                 }/>
          <Popup content={t('XML')}
                 trigger={
                     <Icon name='circular code' onClick={() => onClickCallback('xml')}/>
                 }/>
          <Popup content={t('SKOS')}
                 trigger={
                     <Icon name='circular code branch' onClick={() => onClickCallback('skos')}/>
                 }/>
          <Popup content={t('JSON')}
                 trigger={
                     <Icon name='circular node js' onClick={() => onClickCallback('json')}/>
                 }/>
      </div>
    );

    // noinspection RequiredAttributes
    return (
        <Segment
            textAlign="center"
            style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
            vertical={true}
        >
            <Container text={true}>
                <Header as="h2" icon={true}>
                    <Icon name="search"/>
                    {t('FILTER')}
                </Header>

                <Segment>
                    <Form onSubmit={handleSubmit} id='filter-form'>
                        <div id='filter-languageSelect'>
                            {(settings && settings.dict && settings.dict.langs) ? (
                              <LanguageSelect
                                languages={settings.dict.langs}
                                current={currentLang}
                                callback={setCurrentLang}
                              />
                            ) : (
                              <i className="spinner loading icon" style={{opacity: 0.4}}/>
                            )}
                        </div>

                        {loggedUser && loggedUser.roles ? (
                            <>
                                <Input fluid><Text field='search' placeholder={t('SEARCH_TEXT')}/></Input><br/>
                                <Input fluid><Text field='definition' placeholder={t('DEFINITION')}/></Input><br/>
                                <Input fluid>
                                    <Select fluid clearable search
                                            options={settings.editorsFormatted}
                                            loading={!settings.editorsFormatted}
                                            onChange={(e, option) => setAuthor(option.value)}
                                            placeholder={t('AUTHOR')}
                                    />
                                </Input><br/>
                                {authorize(settings.roles && removeFromArr(settings.roles.map(e => e.role), 'host'), loggedUser.roles) && (
                                    <>
                                        <Input fluid>
                                            <Select fluid clearable
                                                    options={[{key: 'public', text: t('STATE_PUBLIC'), value: 'public'}, {key: 'work', text: t('STATE_WORK'), value: 'work'}]}
                                                    onChange={(e, option) => setState(option.value)}
                                                    placeholder={t('STATE')}
                                            />
                                        </Input><br/>
                                  </>
                                )}
                                <Input fluid>
                                    <DatesRangeInput allowSameEndDate clearable closable iconPosition='right'
                                                     name='datesRange'
                                                     placeholder={t('DATE_RANGE')}
                                                     value={datesRange}
                                                     onChange={(e, {value}) => { setDatesRange(value) }}
                                                     dateFormat={'DD. MM. YYYY'}
                                    />
                                </Input><br/>
                                <Input fluid>
                                    <DatesRangeInput allowSameEndDate clearable closable iconPosition='right'
                                                     name='datesChange'
                                                     placeholder={t('DATE_CHANGE')}
                                                     value={datesChange}
                                                     onChange={(e, {value}) => { setDatesChange(value) }}
                                                     dateFormat={'DD. MM. YYYY'}
                                    />
                                </Input><br/>
                                <Input fluid>
                                    <EnhancedDropdown options={settings.domainsFormattedText}
                                                      selectOptions={setDomains}
                                                      placeholder={t('DOMAINS_SELECT')}
                                    />
                                </Input><br/>
                                <Input fluid>
                                    <EnhancedDropdown options={settings.typesFormatted}
                                                      selectOptions={setTypes}
                                                      placeholder={t('TYPES')}
                                    />
                                </Input><br/>
                                <Input fluid>
                                    <EnhancedDropdown options={settings.druhyFormatted}
                                                      selectOptions={setKinds}
                                                      placeholder={t('KIND')}
                                    />
                                </Input><br/>
                                <Input fluid>
                                    <Select fluid clearable
                                            options={[{key: 1, text: t('SHORT_YES'), value: true}, {key: 0, text: t('SHORT_NO'), value: false}]}
                                            onChange={(e, option) => setShort(option.value)}
                                            placeholder={t('SHORT')}
                                    />
                                </Input><br/>
                            </>
                        ) : (
                            <div><i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em', margin: '1em 0'}}/></div>
                        )}

                        <Button type='submit' id='filter-formSubmit'>
                            {t('SEARCH')}
                        </Button>

                        <FilterExportButtons onClickCallback={exportWithFilter} id='filter-exportButtons'/>
                    </Form>
                </Segment>

                {data && data.terminologicky && data.terminologicky.results.length ? (
                    <div style={{textAlign: 'left'}}>
                        <div style={{
                          opacity: selectedForExport.length ? 1 : 0,
                          pointerEvents: selectedForExport.length ? undefined : 'none',
                          transition: 'opacity .4s ease',
                        }}>
                            {t('EXPORT_SELECTED')}: <FilterExportButtons onClickCallback={exportSelected} id='filter-exportSelectedButtons'/>
                        </div>
                        <List link className='searchResults'>
                            {data.terminologicky.results.map((e) => (
                                <List.Item key={e.id}>
                                    <div className='ui checked fitted checkbox' onClick={() => toggleSelected(e.id)}>
                                        <input type='checkbox'/>
                                        <label/>
                                    </div>
                                    <a href={'/public/tezaurus/term/' + e.id} style={{marginLeft: '.5em', verticalAlign: 'top'}} target='_blank' rel='noopener noreferrer'>
                                        {e.title}
                                    </a>
                                </List.Item>
                            ))}
                        </List>
                    </div>
                ) : data && (data.length === 0 || data.terminologicky.length === 0 || (data.terminologicky.results && data.terminologicky.results.length === 0)) ? (
                  <>
                    <i style={{opacity: 0.4}}>{t('NO_RESULTS')}</i>
                    <br/><br/>
                    {(loggedUser && loggedUser.roles && loggedUser.roles.length === 1 && loggedUser.roles[0] === "host") && (
                      <Button onClick={() => {
                        History.push('/public/tezaurus/remark/' + t('NEW') + (lastSearchTerm ? ' – ' + (lastSearchTerm) : ''))
                      }} className='term-editBttn'>{t('NEW_TERM_SUGGEST')}</Button>
                    )}
                  </>
                ) : data === undefined ? (
                    <>
                        <i style={{opacity: 0.4}}>{t('LOADING')}</i>
                        <br/><br/>
                        <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em', marginBottom: '822px'}}/>
                    </>
                ) : data === 0 ? (
                    <></>
                ) : (
                    <i style={{opacity: 0.4, color: 'red'}}>{t('ERROR')}</i>
                )}
            </Container>
        </Segment>
    );
};

export default translate('SearchResults')(SearchResults);
