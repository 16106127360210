import * as React from 'react'
import {translate} from 'react-translate'
import {Header, Icon, Segment} from 'semantic-ui-react'

const Sources = ({entry, t}) => (
  <>
    <Header as='h3' attached='top'>
      <Icon name='external alternate'/>{t('SOURCES')}
    </Header>
    <Segment attached='bottom'>
      <ol id='sources'>
        {entry.defs.def.map((e, i) => !!(e.zdroj && e.zdroj.$) && (
          <li key={`source${i}`}>{e.zdroj.$}</li>
        ))}
      </ol>
    </Segment>
  </>
)

export default translate('Term')(Sources)
