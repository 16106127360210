import * as React from 'react';
import {translate} from "react-translate";
import {Input} from 'semantic-ui-react';
import {Form, Text, TextArea} from "informed";

import EnhancedDropdown from "../components/enhanced/EnhancedDropdown";
import EnhancedSubmitButton from "../components/enhanced/EnhancedSubmitButton";
import Context from "../Context";
import ContextUser from "../ContextUser";
import {authorize} from "../utilsNew";

const UserEditForm = ({handleSubmit: handleSubmitCallback, newUser, initialValues, setRoles, saved, t}) => {
  const {settings} = React.useContext(Context);
  const {loggedUser} = React.useContext(ContextUser);

  const handleSubmit = (values, ...props) => {
    if (values.password && values.password !== values.password2) {
      alert(t('PASSWORD_MATCH_FAIL'))
    } else {
      handleSubmitCallback(values, ...props)
    }
  }

  return (
    <Form onSubmit={handleSubmit} className='ui form' initialValues={initialValues}>
      <Input fluid disabled={!newUser}><Text field='login' placeholder={t('LOGIN')} required={newUser}/></Input><br/>
      <Input fluid><Text field='name' placeholder={t('NAME')}/></Input><br/>
      <Input fluid><Text type="password" field='password' placeholder={t('PASSWORD')} required={newUser}/></Input><br/>
      <Input fluid><Text type="password" field='password2' placeholder={t('PASSWORD_CHECK')}/></Input><br/>
      <Input fluid><Text field='email' placeholder={t('EMAIL')}/></Input><br/>
      <Input fluid><Text field='phone' placeholder={t('PHONE')}/></Input><br/>
      <Input fluid><Text field='organization' placeholder={t('ORG')}/></Input><br/>
      <EnhancedDropdown options={settings.rolesFormatted} selectOptions={setRoles}
                        defaultValue={initialValues.roles} placeholder={t('ROLES')}
                        disabled={!authorize(['hl_redaktor', 'asist_hl'], loggedUser.roles)}
      /><br/>
      <TextArea field='notes' placeholder={t('NOTES')} style={{minHeight: '7.5em', display: 'block'}}/><br/>
      <EnhancedSubmitButton saved={saved}/>
    </Form>
  );
};

export default translate('UserEdit')(UserEditForm);
