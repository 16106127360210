import React from 'react';
import {Text} from "informed";

import {EnhancedDropdownH} from "../informedHighlight";

const TextWithDropdown = ({options, formApi, field, placeholder, defaultValue, className}) => (
  <>
    <EnhancedDropdownH
      multiple={false}
      options={options}
      selectOptions={e => formApi.setValue(field, e)}
      placeholder={placeholder}
      defaultValue={defaultValue}
      className={className}
    />
    <Text field={field} style={{display: 'none'}}/>
  </>
);

export default TextWithDropdown;
