export default {
  locale: 'cs',
  Appbar: {
    CENTRAL: 'Central',
    SETTINGS: 'Nastavení',

    ADD: 'Přidat',
    FILTER: 'Filtr',
    LOADING: 'Načítání...',
    LOGIN: 'Přihlásit',
    LOGOUT: 'Odhlásit',
    REGISTER: 'Registrovat',
    CLIENT: 'Klient',
    ADMINISTRATOR: 'Administrátor',
    OVERVIEW: 'Přehled',
    TRANSACTIONS: 'Historie transakcí',
    TREND: 'Vývoj',
    ALL_USERS: 'Přehled uživatelů',
    ALL_CARDS: 'Přehled karet',
    ALL_ACTIVITIES: 'Přehled aktivit',
    USER_PROFILE: 'Uživatelský profil',
    CHANGE_PASSWORD: 'Změnit heslo',
    USER: 'Uživatel',
  },
  Search: {
    SEARCH_DOT: 'Hledej...',
    APPROVED: 'Schválené',
    WIP: 'Rozpracované',
    SUGGESTED: 'Navržené',
    ALL: 'Všechny',
    ERROR: 'chyba',
    AT_LEAST_3: 'zadejte alespoň tři znaky'
  },
  Enhanced: {
    SAVE: 'Uložit',
    SAVED: 'Úspěšně uloženo',
    SAVED_BACKUP: 'Odeslán příkaz k zálohování',
    LOADING: 'Probíhá ukládání',
  },

  Homepage: {
    INFO: 'Informace',
    SETTINGS: 'Nastavení',
    EDITED_TERMS: 'Editované termíny',
    DELETED_TERMS: 'Odstraněné termíny',
    CONTACT: 'Kontakt',
    SEARCH_ALPHABET: 'Procházení slovníku',
    HELP: 'Nápověda',
    STATS: 'Statistiky',
    STATS_MORE: 'Statistiky podrobné',
    REMARK: 'Odeslat připomínku',
    APP_DEV: 'Aplikaci vyvinulo Centrum ZPJ FI MU',
    APP_DEV_MORE: 'Informace a připomínky',
    TACR: 'Tento software byl vytvořen se státní podporou Technologické agentury ČR v rámci Programu Beta2, projekt TITIMV702, Metodika a technologie pro tvorbu odborných tezaurů a slovníků pro potřeby rozvoje národní infrastruktury pro prostorové informace.',

    HEADER_CONT: 'Kontaktní údaje',
    ORGANIZATION: 'Organizace',
    POVERENA_ORGANIZACE: 'Pověřená organizace',
    CONTACT_PERSON: 'Kontaktní osoba',
    CONTACT_PHONE: 'Kontaktní telefon',
    CONTACT_EMAIL: 'Kontaktní email',
  },
  HomepageC: {
    LIST: 'Seznam slovníků',
    ADD_NEW: 'Přidat nový'
  },
  HomepageTezC: {
    TIME_REGISTER: 'Datum registrace:',
    TIME_UPDATE: 'Datum poslední změny:',
    TERMS_COUNT: 'Počet termínů:',
    URL: 'Adresa:',

    CONTACT: 'Kontaktní údaje',
    CONTACT_PERSON: 'Kontaktní osoba:',
    CONTACT_PHONE: 'Kontaktní telefon:',
    CONTACT_EMAIL: 'Kontaktní e-mail:',
    DESCRIPTION: 'Popis slovníku',
  },
  StaticInfo: {
    TOTAL: 'Celkový počet termínů',
    TOTAL_BY_LANG: 'Rozdělený podle jazyků',
    TOTAL_DEFS: 'Celkový počet definic',
    TOTAL_BY_DOM: 'Počet definic v oborech',
  },

  SearchResults: {
    FILTER: 'Vyhledávání s filtrem',
    SEARCH_TEXT: 'Hledaný termín',
    TYPES: 'Typ termínu',
    DOMAINS_SELECT: 'Obor termín',
    DATE_RANGE: 'Datum změny',
    DATE_CHANGE: 'Datum založení',
    AUTHOR: 'Autor',
    STATE: 'Stav termínu',
    STATE_PUBLIC: 'Zveřejněný',
    STATE_WORK: 'Rozpracovaný',
    KIND: 'Druh termínu',
    SHORT: 'Je termín zkratka?',
    SHORT_YES: 'Ano',
    SHORT_NO: 'Ne',
    DEFINITION: 'Text definice',
    SEARCH: 'Hledej',

    ALPHABET: 'Abecední hledání',
    NO_RESULTS_FOUND: 'Nebyly nalezeny žádné termíny',
    NEW: 'nový',
    NEW_TERM_SUGGEST: 'Navrhnout nový termín',
    BROWSE_TERM: 'Termíny',
    BROWSE_ABBR: 'Zkratky',

    NO_RESULTS: 'Vyhledávanému řetezci neodpovídají žádné termíny',
    LOADING: 'Probíhá načítání...',
    ERROR: 'Při zpracovaní požadavku nastala chyba, omlouváme se',

    EXPORT_SELECTED: 'Stáhnout vybrané',
    PDF: 'Stáhnout PDF',
    PDF_SHORT: 'Stáhnout zkrácené PDF',
    RTF: 'Stáhnout RTF',
    RTF_SHORT: 'Stáhnout zkrácené RTF',
    XML: 'Stáhnout XML',
    SKOS: 'Stáhnout SKOS',
    JSON: 'Stáhnout JSON',
  },
  TermAdd: {
    ADD_TERM: 'Přidat nový termín',
    TERM: 'Termín...',
    SEARCH_TEZ: 'Prohledat tezaury',
    CREATE: 'Vytvořit nový termín',
    NO_RESULTS: 'Vyhledávanému řetezci neodpovídají žádné termíny',
    ERR_EXISTS: 'Termín "{{term}}" už existuje. Chcete jej zobrazit?',
    ERR_SIMILAR_TERMS: 'Existující podobné termíny',
    ERR_SIMILAR_TERMS_CONTINUE: 'Přesto vytvořit nový termín',
  },

  Settings: {
    SETTINGS: 'Nastavení',
    TECH: 'Technická',
    DICTIONARY: 'Slovník',
    USERS: 'Uživatelé',
    IE: 'Import/export',
    LIST_OF_RIGHTS: 'Seznam práv uživatele',
    REQUIRED_ONE_USER_ROLE: 'Zvolte alespoň jedno právo pro uživatele.',
    DESCRIPTION: 'Zde můžete změnit veřejné informace o svém slovníku.',
    INFO: 'Metadata',
    THEME: 'Design',
    THEME_CREATOR: 'Vytvoření stylu',
    DESCRIPTION_THEME: 'Vyberte si barvy, nahrajte logo a stiskněte tlačítko "Generovat".',
    GENERATE: 'Generovat',
    COLORS: 'Nastavení barev',
    LOGO: 'Nahraní loga',
    MSG_OK: 'Nastavení uloženo, design aplikace se do minuty přegeneruje.',
    MSG_ERROR: 'Chyba při ukládání nastavení.',

    TEXT_COLOR: 'Barva textu',
    SEGMENT_BACKGROUND: 'Barva segmentu',
    BODY_BACKGROUND: 'Barva pozadí',
    BAR_COLOR: 'Lišta – text',
    BAR_BACKGROUND: 'Lišta – pozadí',
    TREE_NODE: 'Barva uzlů ve stromu v segmentu "Odkazy"',
    VISUALIZATION_COLOR: 'Barva vizualizace',
    POSITIVE: 'Potvrzení',
    FOCUS_COLOR: 'Aktivní prvky',
    ERROR_COLOR: 'Chyba',

    APPROVAL_PROCESS: 'Schvalovací proces',
    COMPLEX: 'Komplexní',
    SIMPLE: 'Zjednodušený',

    DRAG: 'Pro nahrání souborů klikněte, nebo je sem přesunte',
    DROP: 'Nyní můžete uvolnit tlačítko myši',

    SHOW_TREE: 'Zobrazovat strom',
    SMTP_SERVER: 'Adresa SMTP serveru',
    SMTP_PORT: 'SMTP port',
    SMTP_USER: 'SMTP uživatel',
    SMTP_PASS: 'SMTP heslo',
    LOG_PATH: 'Adresa logů',
    BACKUP_PATH: 'Zálohy – cesta',
    BACKUP_URL: 'Zálohy – URL',
    BACKUP_USER: 'Zálohy – uživatel',
    BACKUP_PASS: 'Zálohy – heslo',
    BACKUP_TIME: 'Interval tvorby záloh',
    WEEK: 'Týdně',
    DAY: 'Denně',
    NEVER: 'Nikdy',

    EXAMPLES: 'Počet příkladů',
    EXAMPLE_LENGTH: 'Maximální délka příkladu',
    DOMAINS: 'Domény',
    TYPES: 'Typy',
    KINDS: 'Druhy',
    LANGUAGES: 'Jazyky',
    REQ_TRANS: 'Povinné jazyky',
    SKOS: 'SKOS',
    DICTIONARIES: "Slovníky",
    NOTIFICATION_AFTER_DAYS: 'Upozornit na nevyřízené úkoly po dnech',
    ADD_TO_DROPDOWN: 'Přidat novou položku: ',
    ERRORS_FOUND: 'Ve formuláři byly nalezeny chyby',
    ERROR_DICT_SKOS_NOT_URL: 'Nerozpoznána URL: ',

    NEW_USER: 'Vytvořit uživatele',

    CREATE_BACKUP: 'Vytvořit zálohu',
    DB: 'Vytvořit zálohu databáze',
    APP: 'Vytvořit zálohu aplikace',
    LOGS: 'Vytvořit zálohu logů',
    BACKUP_ACTION: 'Zálohovat',

    SURE: 'Určitě chcete smazat tohoto uživatele?',
    EDIT: ' – nastavení uživatele',
    CANCEL: 'Zrušit',
    DELETE: 'Smazat',
    SAVE: 'Uložit',
    SAVED: 'Úspěšně uloženo',
  },
  UserEdit: {
    SETTINGS: 'Úprava profilu',
    SAVED: 'Uloženo',
    LOGIN: 'Přihlašovací jméno',
    NAME: 'Celé jméno',
    PASSWORD: 'Heslo',
    PASSWORD_CHECK: 'Heslo znovu pro kontrolu',
    PASSWORD_MATCH_FAIL: 'Hesla se neshodují!',
    PHONE: 'Kontaktní telefon',
    EMAIL: 'E-mail',
    ORG: 'Organizace',
    ROLES: 'Práva uživatele',
    NOTES: 'Poznámka',
    SAVE: 'Uložit'
  },
  AddNew: {
    ADD_NEW: 'Přidat nový slovník',
  },
  Term: {
    404: 'Termín nenalezen',
    LOADING: 'Probíhá načítání...',
    ERROR: 'Při zpracovaní požadavku nastala chyba, omlouváme se',
    NEW_TERM: 'Nový termín',
    PRINT: 'Vytisknout stránku',
    XML: 'Stáhnout XML',
    SKOS: 'Stáhnout SKOS',
    JSON: 'Stáhnout JSON',
    PDF: 'Stáhnout PDF',
    PDF_SHORT: 'Stáhnout zkrácené PDF',
    RTF: 'Stáhnout RTF',
    RTF_SHORT: 'Stáhnout zkrácené RTF',
    ABBREV: 'zkratka',
    DATE: 'datum',
    TRANSLATE: 'Překlady',
    SEARCH_SKOS: 'Prohledat externí SKOS',
    DOMAINS: 'Obory',
    DEFINITION: 'Definice',
    SOURCE: 'Zdroj/citace',
    LAW: 'Zdroj ze zákona',
    MATH: 'Vzorce',
    TABLE: 'Tabulky',
    OBOR: 'Obor',
    DRUH: 'Druh',
    TYPE: 'Typ',
    MORE_INFO: 'Doplňující informace',
    PRIVATE_INFO: 'Neveřejná poznámka',
    LINKS: 'Odkazy',
    VISUALIZATION: 'Vizualizace struktury',
    SUPERIOR: 'Nadřazené pojmy',
    ADD_NEW_TREE: 'Přidat nový strom',
    SYN: 'Synonyma',
    ANT: 'Antonyma',
    HOM: 'Homonyma',
    ADD_NEW_SEE: 'Přidat nový termín',
    WILL_BE_ADDED: 'Budou přidané termíny:',
    EXAMPLES: 'Příklady použití',
    PICS: 'Obrázky',
    PICS_EDIT: 'Obrázky (jako odkaz)',
    VIDS: 'Videa',
    VIDS_EDIT: 'Videa (jako odkaz)',
    OTHERS: 'Odkazy do jiných Tezaurů',
    SOURCES: 'Zdroje',
    META: 'Stav termínů',
    AUTHOR: 'Autor',
    CREATION_TIME: 'Datum vytvoření',
    PUBLISHED_TIME: 'Datum zveřejnění',
    UPDATE_TIME: 'Datum poslední úpravy',
    HISTORY: 'Historie editace',
    HISTORY_VERSIONS: 'Historie verzí',
    SHOW: 'Zobrazit',
    EDIT: 'Upravit',
    EDIT_SUGGEST: 'Návrh na úpravu',
    COMMENT: 'Redakční poznámka',

    ERRORS_FOUND: 'Ve formuláři byly nalezeny chyby',
    TRANS_TEXT: 'Význam',
    TRANS_PRON: 'Výslovnost',
    TRANS_NOTE: 'Poznámka',
    TRANS_SHORT: 'Zkratka',
    ERROR_SELECT_DOMAIN_FOR_DEF: 'Definice nemá zvolený obor: ',
    ERROR_MULTIPLE_DIFFERENT_DOMAINS: 'Definice má odlišný obor: ',
    ERROR_SOURCE_FOR_DEF: 'Definice bez uvedeného zdroje: ',
    ERROR_NO_VALID_DEF: 'Je vyžadována alespoň jedna definice',
    ERROR_REQ_TRANS: 'Chybí překlady',
    ERROR_REQ_TRANS_MISSING_ONE: 'Chybí překlad do požadovaného jazyka',
    ERROR_NOT_RECOGNIZE_AS_URL_IMG: 'URL adresa obrázku nebyla rozpoznána: ',
    ERROR_NOT_RECOGNIZE_AS_URL_VIDEO: 'URL adresa videa nebyla rozpoznána: ',
    ERROR_NOT_RECOGNIZE_AS_URL_EXLINK: 'URL adresa odkazu nebyla rozpoznána: ',

    MODAL_HEADER: 'Nápověda možného překladu',
    NO_RESULTS: 'Žádné výsledky',

    CLOSE: 'Zavřít',
    DELETE: 'Zrušení termínu',
    DELETE_SURE: 'Určitě chcete tento termín zrušit? Potvrzením OK bude termín zrušen.',
    SAVE: 'Uložit',
    READ: 'Přečteno',
    APPROVE: 'Schválit',
    DENY: 'Zamítnout',
    SURE_APPROVE: 'Opravdu chcete schválit tyto změny?',
    SURE_DENY: 'Opravdu chcete zamítnout tyto změny?',
    REQUIRED: ' (vyžadovaná)',
    YES: 'Ano',
    CANCEL: 'Zrušit',
    RETURN_TO_REWORK: 'Vrátit k přepracování',
    RETURN_SURE: 'Určitě chcete termín vrátit k přepracování?',

    TERMS_EDITED: 'Editované termíny',
    WAITING_FOR: 'Čeká na',
    TERMS_DELETED: 'Odstraněné termíny',
    RECOVER_TERM: 'Obnovit termín',
  },
  TezInfoForm: {
    NAME: 'Název slovníku',
    URL: 'URL aplikace',
    ORG: 'Název organizace',
    PERSON: 'Kontaktní osoba',
    PHONE: 'Kontaktní telefon',
    MAIL: 'Kontaktní e-mail',
    NAME_SHORT: 'Zkrácený název',
    COMMISSION: 'Komise',
    APPR_ORG: 'Pověřená organizace',
    DESCRIPTION_FIELD: 'Popis',
    DESCRIPTION_FIELD_EN: 'Popis anglicky',
    INFO_FIELD: 'Základní informace',
    INFO_FIELD_EN: 'Základní informace v angličtině',
    SEND: 'Odeslat do registru',
  },
  Notifications: {
    NOTIFICATIONS: 'Notifikace',
    FILTER: 'Filtr',
    SEARCH_TEXT: 'Termín',
    DATE_RANGE: 'Rozsah data',
    ROLES: 'Role',
    ALL: 'Všechny',
    SERVER: 'Centrální systém',
    LOCAL: 'Datový systém',
    ACTIVE: 'Aktivní notifikace',
    SOLVED: 'Vyřešené',
    SEARCH: 'Vyhledej',
    READ: 'Přečteno',
    UNREAD: 'Označit jako nepřečtené',
  },
  Remark: {
    REMARK: 'Návrh na úpravu',
    SAVED: 'Úspěšně uloženo',
    NAME: 'Vaše jméno',
    EMAIL: 'Váš email',
    TEXT: 'Text komentáře',
    SEND: 'Odeslat',
  }
};
