import * as React from 'react';
import {translate} from "react-translate";
import {Container, Header, Icon, Segment, Button} from 'semantic-ui-react';

import {authorize} from "../../utilsNew";
import ContextUser from "../../ContextUser";

const BasicSettingsPage = ({history, current, children, saved, t}) => {
    const {loggedUser} = React.useContext(ContextUser);

    return (
        <Segment
            className='settings-segment'
            textAlign="center"
            vertical={true}
        >
            <Container text={true}>
                {saved ? (
                    <Header as="h2" icon={true} onClick={() => history.push('/public/tezaurus/settings')}
                            style={{color: 'green'}}>
                        <Icon name="save outline"/>
                        {t('SAVED')}
                    </Header>
                ) : (
                    <Header as="h2" icon={true} onClick={() => history.push('/public/tezaurus/settings')}>
                        {saved === null ? <Icon name="spinner loading icon" style={{opacity: 0.4}}/> :
                            <Icon name="settings"/>}
                        {t('SETTINGS')}
                    </Header>
                )}
                <br/>
                {authorize(['hl_redaktor'], loggedUser.roles) && (
                    <Button positive={current === 1} active={current === 1} icon='address book outline'
                            content={t('INFO')} labelPosition='left'
                            onClick={() => history.push('/public/tezaurus/settings/metadata')}
                    />
                )}
                {authorize(['asist_hl'], loggedUser.roles) && (
                    <Button positive={current === 2} active={current === 2} icon='theme' content={t('THEME')}
                            labelPosition='left'
                            onClick={() => history.push('/public/tezaurus/settings/theme')}
                    />
                )}
                {authorize(['it_admin', 'operator'], loggedUser.roles) && (
                    <Button positive={current === 3} active={current === 3} icon='setting' content={t('TECH')}
                            labelPosition='left'
                            onClick={() => history.push('/public/tezaurus/settings/tech')}
                    />
                )}
                {authorize(['hl_redaktor', 'asist_hl'], loggedUser.roles) && (
                    <Button positive={current === 4} active={current === 4} icon='file alternate outline'
                            content={t('DICTIONARY')} labelPosition='left'
                            onClick={() => history.push('/public/tezaurus/settings/dictionary')}
                    />
                )}
                {authorize(['hl_redaktor', 'asist_hl'], loggedUser.roles) && (
                    <Button positive={current === 5} active={current === 5} icon='users' content={t('USERS')}
                            labelPosition='left'
                            onClick={() => history.push('/public/tezaurus/settings/users')}
                    />
                )}
                {authorize(['hl_redaktor', 'it_admin', 'operator', 'asist_hl'], loggedUser.roles) && (
                    <Button positive={current === 6} active={current === 6} icon='upload' content={t('IE')}
                            labelPosition='left'
                            onClick={() => history.push('/public/tezaurus/settings/import-export')}
                    />
                )}
                <br/><br/>
                {children}
            </Container>
        </Segment>
    );
};

export default translate('Settings')(BasicSettingsPage);
