import * as React from 'react';
import {translate} from 'react-translate';
import {Container, Header, Icon, Segment} from 'semantic-ui-react';

import TezInfoForm from '../modulesUniversal/TezInfoForm';

import {updateThesaurus} from './_actionsCentral';

const handleSubmit = async (data) => {
    await updateThesaurus(data);
};

const AddNewC = ({t}) => (
    <Segment
        textAlign="center"
        style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
        vertical={true}
    >
        <Container text={true}>
            <Header as="h2" icon={true}>
                <Icon name="newspaper"/>
                {t('ADD_NEW')}
            </Header>
            <br/>
            <TezInfoForm handleSubmit={handleSubmit}/>
        </Container>
    </Segment>
);

export default translate('AddNew')(AddNewC);
