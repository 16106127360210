import {ROOT} from "../constants";

export const logout = e => {
  e.preventDefault();

  if (document.execCommand("ClearAuthenticationCache")) {
    window.location = 'http://' + window.location.host + process.env.PUBLIC_URL;
  } else {
    const xhttp = new XMLHttpRequest();
    xhttp.open('GET', `${ROOT}/public/tecu?action=init`, false, 'log', 'out');
    xhttp.send();

    window.location = 'http://' + window.location.host + process.env.PUBLIC_URL;
  }
};

export default logout;
