import React from "react";
import {Select} from "semantic-ui-react";
import {Text, TextArea, useFormState} from "informed";

import ContextInitialValues from "../../ContextInitialValues";
import EnhancedDropdown from "../enhanced/EnhancedDropdown";

const untouched = field => {
  const formStateValues = useFormState().values;
  const {initialValues} = React.useContext(ContextInitialValues);
  // noinspection EqualityComparisonWithCoercionJS
  return (formStateValues[field] == initialValues[field]) || (!formStateValues[field] && !initialValues[field]);
};

export const TextH = ({field, required, validateOnBlur, validate}) => (
  <Text field={field} required={required} validateOnBlur={validateOnBlur} validate={validate}
        className={!untouched(field) ? 'hgltd' : 'notHgltd'}/>
);

export const TextAreaH = ({field, required, validateOnBlur, validate}) => (
  <TextArea field={field} required={required} validateOnBlur={validateOnBlur} validate={validate}
            className={!untouched(field) ? 'hgltd' : 'notHgltd'}/>
);



const onChangeHighlight = (e, option, setHighlighted, {onChange}) => {
  setHighlighted(!((option.defaultValue === option.value) || (!option.defaultValue && !option.value)));
  onChange(e, option);
};

export const SelectH = props => {
  const [highlighted, setHighlighted] = React.useState(false);
  return <Select
    {...props} onChange={(e, option) => onChangeHighlight(e, option, setHighlighted, props)}
    className={highlighted ? 'hgltd' : 'notHgltd'}
  />;
};



const selectOptionsHighlight = (e, defaultValue, setHighlighted, selectOptions) => {
  setHighlighted(!((e === defaultValue) || (!e && !defaultValue)));
  selectOptions(e);
};

export const EnhancedDropdownH = ({options, setOptions, selectOptions, placeholder, defaultValue, allowAdditions, additionLabel, multiple, deburr, disabled, className}) => {
  const [highlighted, setHighlighted] = React.useState(false);
  return (
    <EnhancedDropdown
      options={options}
      setOptions={setOptions}
      selectOptions={e => selectOptionsHighlight(e, defaultValue, setHighlighted, selectOptions)}
      placeholder={placeholder}
      defaultValue={defaultValue}
      allowAdditions={allowAdditions}
      additionLabel={additionLabel}
      multiple={multiple}
      deburr={deburr}
      disabled={disabled}
      className={(className ? className : '') + (highlighted ? ' hgltd' : '')}
    />
  );
};
