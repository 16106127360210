import {ROOT} from '../constants'
import {customFetch as fetch} from '../utilsNew';

export const getdoc = async (id, type, timestamp) => {
    try {
        const typeUrl = type ? ('&type=' + type) : '';
        const timestampUrl = timestamp ? ('&timestamp=' + timestamp) : '';
        const url = `${ROOT}/public/tecu?action=getdoc&format=json&id=${id}${typeUrl}${timestampUrl}`;
        return await fetch(url, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return {entry: null};
    }
};

export const getconc = async (term) => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=getconc&term=${term}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return {entry: null};
    }
};

export const gethistory = async id => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=get_history&entry_id=${id}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return {entry: null};
    }
};

export const update = async (data, id) => {
    try {
        const body = {};

        body.id = id ? id : '';
        body.action = 'save';
        body.data = data;

        return await fetch(`${ROOT}/public/tecu`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(body),
        });
    } catch (err) {
        console.warn(err);
        return {entry: null};
    }
};

export const gettrans = async (lang, term) => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=gettrans&language=${lang}&term=${term}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return {entry: null};
    }
};

export const gethypcand = async (term) => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=gethypcand&term=${term}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return {entry: null};
    }
};

export const get_waiting = async () => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=get_waiting`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return {entry: null};
    }
}

export const get_deleted = async () => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=get_deleted`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return {entry: null};
    }
}

export const get_settings = async () => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=get_settings`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const save_settings = async (data) => {
    const body = {};

    delete data.rolesFormatted;
    delete data.editorsFormatted;
    delete data.domainsFormatted;
    delete data.domainsFormattedText;
    delete data.druhyFormatted;
    delete data.typesFormatted;
    delete data.langsFormatted;
    delete data.skosFormatted;
    delete data.dictFormatted;

    body.action = 'save_settings';
    body.data = data;

    try {
        return await fetch(`${ROOT}/public/tecu`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(body),
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const send_settings = async (data) => {
    const body = {};

    body.action = 'send_meta';
    body.data = data;

    try {
        return await fetch(`${ROOT}/public/tecu`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(body),
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const get_stats = async () => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=stats`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return {};
    }
}

export const get_init = async () => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=init`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return {logged_user: [{name: 'ERROR'}]};
    }
};

export const get_users = async () => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=get_users`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const save_user = async (data, isNew) => {
    try {
        const body = {};

        body.action = 'save_user';
        body.data = data;
        if (isNew) body.data.new = 1;

        return await fetch(`${ROOT}/public/tecu`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(body),
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const delete_user = async (login) => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=delete_user&login=${login}`, {
            credentials: 'include',
            method: 'GET',
        })
    } catch (err) {
        console.warn(err);
        return false;
    }
};

export const action_return_to_rework = async (id) => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=return_to_rework&id=${id}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const action_delete = async (id) => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=delete&id=${id}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const listNotifications = async (data) => {
    let filter = '';
    if (data) {
        Object.keys(data).forEach((key) => {
            filter = filter + '&' + key + '=' + data[key];
        })
    }

    try {
        return await fetch(`${ROOT}/public/tecu?action=list_notifications${filter}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const ie = async () => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=ie`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const get_tree = async () => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=get_tree`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const get_path = async (id_prop) => {
    try {
        return (await fetch(`${ROOT}/public/tecu?action=get_path&format=json&id=${id_prop}`, {
            credentials: 'include',
            method: 'GET',
        }))[0];
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const backup = async (params) => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=backup${params}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

/**
 * @param action  - string 'schvaleno' or 'zamitnuto'
 * @param id      - id of notification
 * @param comment - comment of the change
 */
export const notificationAction = async (action, id, comment) => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=${action}&id=${id}${comment ? ('&comment=' + comment) : ''}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const get_viz = async (id) => {
    try {
        return await fetch(`${ROOT}/public/tecu?action=get_viz&id=${id}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return null;
    }
};

export const save_design = async (data) => {
    const body = {};

    body.action = 'save_design';
    body.data = data;

    try {
        return await fetch(`${ROOT}/public/tecu`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(body),
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const send_remark = async (data) => {
    try {
        const body = {};

        body.action = 'send_remark';
        body.data = data;

        return await fetch(`${ROOT}/public/tecu`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(body),
        });
    } catch (err) {
        console.warn(err);
        console.warn(err);
        return [];
    }
};
