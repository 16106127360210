import * as React from 'react';
import {translate} from 'react-translate';
import {Button, Header, Icon, Segment, Table} from 'semantic-ui-react';

import {gethistory} from '../_actions';
import {dateAndTimeSecFormat} from "../../utilsNew/date";

const History = ({t, id}) => {
  const [historyShow, setHistoryShow] = React.useState(false);
  const [entry, setEntry] = React.useState(undefined);

  React.useEffect(() => {
    if (historyShow) getTermHistory(id);
  }, [historyShow, id]);

  const getTermHistory = async idQuery => {
    setEntry(undefined);
    const entryNew = (await gethistory(idQuery));
    setEntry(entryNew);
  };

  const VersionRowElement = ({e}) => (
    <a href={`/public/tezaurus/term/${id}/${e.timestamp}/${e.time.toString()}`} target='_blank' rel='noopener noreferrer'>
      {dateAndTimeSecFormat(e.time)}
    </a>
  );

  return (
    <>
      <Header as='h3' attached={historyShow ? 'top' : false} block={!historyShow} className='group'
              style={{transition: 'all .2s ease'}}>
        <div style={{float: 'left'}}>
          <span style={{opacity: .4}}><Icon name='file archive outline'/>{t('HISTORY_VERSIONS')}</span>
        </div>
        <div style={{float: 'right'}}>
          <Button toggle compact size='mini' active={historyShow}
                  onClick={() => setHistoryShow(!historyShow)}>
            {t('SHOW')}
          </Button>
        </div>
      </Header>
      {historyShow && (
        <Segment className='toggleAbleContent' attached='bottom'>
          {entry && entry.map ? (
            <Table compact style={{border: 'none'}}>
              <Table.Body>
                {entry.map(e => (
                  <Table.Row>
                    <Table.Cell>
                      <Icon name='clock outline'/>
                      <VersionRowElement e={e}/>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <div style={{textAlign: 'center', padding: '2em 0', pointerEvents: 'none'}}>
              <i className="spinner loading icon"
                 style={{opacity: 0.4, fontSize: '2em', transformOrigin: 'inherit'}}/>
            </div>
          )}
        </Segment>
      )}
    </>
  );
};

export default translate('Term')(History);
