import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';

import App from './App';
import {Provider} from './Context'
import {ProviderUser} from "./ContextUser";
import * as c from './constants';
import './styles/style.css';

if (c.CENTRAL) document.getElementById('root').classList.add('central');
ReactDOM.render(<Provider><ProviderUser><App/></ProviderUser></Provider>, document.getElementById('root'));
