import * as React from 'react';
import {translate} from 'react-translate';
import {Link} from 'react-router-dom';
import {Button, Container, Header, Icon, Popup, Segment} from 'semantic-ui-react';

import History from "../history";
import {getconc, getdoc} from './_actions';
import {authorize, removeFromArr} from '../utilsNew';

import TermLinks from "./TermEdit/TermLinks";
import MainHeader from "./TermEdit/MainHeader";
import Translate from "./TermEdit/Translate";
import Domains from "./TermEdit/Domains";
import Definitions from "./TermEdit/Definitions";
import Sources from "./Term/Sources";
import Others from "./TermEdit/Others";
import Examples from "./Term/Examples";
import Meta from "./Term/Meta";
import Notes from "./Term/Notes";
import HistoryOfTerm from "./Term/History";
import Pictures from "./TermEdit/Pictures";
import Videos from "./TermEdit/Videos";
import ContextUser from "../ContextUser";
import Context from "../Context";

const Term = ({t, match}) => {
    const id_prop = match.params.id;
    const timestamp = match.params.timestamp;
    const date = match.params.date;
    const {settings} = React.useContext(Context);
    const {loggedUser} = React.useContext(ContextUser);

    const [entry, setEntry] = React.useState({loading: true});
    const [id, setId] = React.useState(undefined);
    const [context, setContext] = React.useState(undefined);

    React.useEffect(() => {
        getEntry(id_prop, timestamp);
    }, [id_prop, timestamp]);

    const getEntry = async (idQuery, timestamp) => {
        setId(idQuery);
        setEntry({loading: true});
        const entryNew = (await getdoc(idQuery, undefined, timestamp)).entry;
        setEntry(entryNew);
        entryNew && entryNew.terms && setContext(await getconc(entryNew.terms.term[0].$));
    };

    const printPage = () => {
        document.getElementById('root').className += 'print';
        window.print();
        document.getElementById('root').classList.remove('print');
    };

    const controlButtonsURIParams = '&id=' + id + (timestamp ? ('&timestamp=' + timestamp) : '');

    return (
        <Segment
            textAlign="center"
            style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
            vertical={true}
        >
            <Container text={true} style={{position: 'relative'}}>
                {entry && entry.terms ? (
                    <>
                        <div id='term-controlButtons'>
                            <Popup content={t('PRINT')}
                                   trigger={<Icon name='circular print' onClick={printPage}/>}/>
                            <Popup content={t('XML')}
                                   trigger={
                                       <Link to={'/public/tecu?action=getdoc&format=xml' + controlButtonsURIParams} target='_blank'>
                                           <Icon name='circular code'/>
                                       </Link>
                                   }/>
                            <Popup content={t('SKOS')}
                                   trigger={
                                       <Link to={'/public/tecu?action=getdoc&format=xml&tr=skos' + controlButtonsURIParams} target='_blank'>
                                           <Icon name='circular code branch'/>
                                       </Link>
                                   }/>
                            <Popup content={t('JSON')}
                                   trigger={
                                       <Link to={'/public/tecu?action=getdoc&format=json' + controlButtonsURIParams} target='_blank'>
                                           <Icon name='circular node js'/>
                                       </Link>
                                   }/>
                            <br/>
                            <Popup content={t('PDF')}
                                   trigger={
                                     <Link to={'/public/tecu?action=getdoc&format=pdf' + controlButtonsURIParams} target='_blank'>
                                       <Icon name='circular file pdf'/>
                                     </Link>
                                   }/>
                            <Popup content={t('PDF_SHORT')}
                                   trigger={
                                     <Link to={'/public/tecu?action=getdoc&format=pdf2' + controlButtonsURIParams} target='_blank'>
                                       <Icon name='circular file pdf outline'/>
                                     </Link>
                                   }/>
                            <Popup content={t('RTF')}
                                   trigger={
                                     <Link to={'/public/tecu?action=getdoc&format=rtf' + controlButtonsURIParams} target='_blank'>
                                       <Icon name='circular file word'/>
                                     </Link>
                                   }/>
                            <Popup content={t('RTF_SHORT')}
                                   trigger={
                                     <Link to={'/public/tecu?action=getdoc&format=rtf2' + controlButtonsURIParams} target='_blank'>
                                       <Icon name='circular file word outline'/>
                                     </Link>
                                   }/>
                        </div>
                        <MainHeader title={entry.terms.term.length !== 0 && entry.terms.term[0].$}
                                    id={entry['@id']} abbrev={entry['@abbrev']} timestamp={timestamp} date={date}
                        />
                        <br/>
                        <div style={{textAlign: 'left'}}>
                            <Translate entry={entry}/>
                            <Domains entry={entry}/>
                            {entry.defs && entry.defs.def != undefined && entry.defs.def.length && (
                              <>
                                <Definitions entry={entry} edit={false}/>
                                {!!entry.defs.def.filter(e => e.zdroj && e.zdroj.$).length && (
                                    <Sources entry={entry}/>
                                )}
                              </>
                            )}
                            {(entry.paths || entry.synonyms || entry.antonyms || entry.homonyms) && (
                                <TermLinks entry={entry} edit={false}/>
                            )}
                            {context && context.length > 0 && (
                                <Examples context={context}/>
                            )}
                            {entry.imgs && entry.imgs.img && entry.imgs.img.length && (
                                <Pictures entry={entry}/>
                            )}
                            {entry.videos && entry.videos.video && entry.videos.video.length && (
                                <Videos entry={entry}/>
                            )}
                            {entry.exlinks && entry.exlinks.exlink && entry.exlinks.exlink.length && (
                                <Others entry={entry}/>
                            )}
                            {loggedUser && loggedUser.roles && authorize(settings.roles && removeFromArr(settings.roles.map(e => e.role), 'host'), loggedUser.roles) && (
                                entry.meta && (entry.meta.author || entry.meta.creation_time || entry.meta.published_time || entry.meta.update_time) && (
                                    <Meta entry={entry}/>
                                )
                            )}
                            {!timestamp && loggedUser && loggedUser.roles && authorize(settings.roles && removeFromArr(settings.roles.map(e => e.role), 'host'), loggedUser.roles) && (
                                entry.notes && entry.notes.note && entry.notes.note.length && (
                                    <Notes entry={entry}/>
                                )
                            )}
                            {!timestamp && (
                              <HistoryOfTerm id={entry['@id']}/>
                            )}
                        </div>
                        <br/>
                        {!timestamp && (
                          !(loggedUser && loggedUser.roles) ? (
                            <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
                          ) : !(entry['@wait_for'] && (!entry['@wait_for'].length || !authorize(entry['@wait_for'], loggedUser.roles))) ? (
                            <Button onClick={() => {
                                History.push('/public/tezaurus/termEdit?id=' + id)
                            }} className='term-editBttn'>{t('EDIT')}</Button>
                          ) : (
                            <Button onClick={() => {
                                History.push('/public/tezaurus/remark/' + id + ' – ' + (entry.terms.term.length !== 0 && entry.terms.term[0].$))
                            }} className='term-editBttn'>{t('EDIT_SUGGEST')}</Button>
                          )
                        )}
                    </>
                ) : (
                    <>
                        <Header as="h2" icon={true} style={{marginBottom: 0}}>
                            <Icon name="sitemap"/>
                            {entry && entry.loading ? (
                                t('LOADING')
                            ) : (
                                <span style={{color: 'red', opacity: .4}}>{t('404')}</span>
                            )}
                        </Header>
                        <Header as="h6" style={{marginTop: 0}}>
                            ID: {id}
                            {!!timestamp && (' | timestamp: ' + timestamp)}
                        </Header>
                        {entry && entry.loading && (
                            <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
                        )}
                    </>
                )}
            </Container>
        </Segment>
    );
};

export default translate('Term')(Term);
