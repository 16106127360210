import * as React from 'react';
import {Flag} from "semantic-ui-react";

const LanguageSelect = ({languages, current, callback}) => (
  <div className='languageSelect'>
    {languages.map((e, i) => (
      (e.flag && e.lang) ? (
        <Flag title={e.lang} name={e.flag} className={current === e.lang ? 'current' : ''} onClick={() => callback(e.lang)} key={i + e.lang}/>
      ) : (
        <Flag title={e} name={e} className={current === e ? 'current' : ''} onClick={() => callback(e)} key={i + e}/>
      )
    ))}
  </div>
);

export default LanguageSelect
