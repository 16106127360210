import * as React from 'react';
import {translate} from "react-translate";
import {Container, Header, Icon, Segment, List, Button, Input, Radio, Form as FormSe} from 'semantic-ui-react';
import {DatesRangeInput} from "semantic-ui-calendar-react";
import {Form, Text} from "informed";

import {listNotifications} from "../_actions";
import NotificationItem from "./NotificationItem";
import EnhancedDropdown from "../../components/enhanced/EnhancedDropdown";
import Context from '../../Context'

const Notifications = ({t}) => {
  const {settings} = React.useContext(Context);

  const [data, setData] = React.useState(undefined);
  const [filterShow, setFilterShow] = React.useState(false);
  const [role, setRole] = React.useState('');
  const [from, setFrom] = React.useState(undefined);
  const [read, setRead] = React.useState('0');
  const [datesRange, setDatesRange] = React.useState('');

  React.useEffect(() => {
    getListNotifications();
  }, []);

  const handleChangeFrom = (_, {value}) => setFrom(value);
  const handleChangeRead = (_, {value}) => setRead(value);

  const getListNotifications = async () => {
    setData(await listNotifications());
  };

  const handleSubmit = async (data) => {
    const filter = {};

    if (data.search) filter.term = data.search;
    if (datesRange) {
      let splitDate, datesRangeParsed = ['', ''];
      datesRange.split(' - ').forEach((e, i) => {
        splitDate = e.split('. ');
        datesRangeParsed[i] = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
      });

      filter.time_from = datesRangeParsed[0];
      filter.time_to = datesRangeParsed[1];
    }
    if (role) filter.role = role;
    if (from) filter.from = from;
    filter.read = read;

    setData(await listNotifications(filter));
  };

  return (
    <Segment
      textAlign="center"
      style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
      vertical={true}
    >
      <Container text={true}>
        <Header as="h2" icon={true}>
          <Icon name="bell outline"/>
          {t('NOTIFICATIONS')}
        </Header>
        <br/>
        <Button
          toggle
          active={filterShow}
          onClick={() => setFilterShow(!filterShow)}
        >
          {t('FILTER')}
        </Button>
        <Segment style={{display: filterShow ? false : 'none', marginBottom: 0, textAlign: 'left'}}>
          <Form onSubmit={handleSubmit}>
            <Input fluid><Text field='search' placeholder={t('SEARCH_TEXT')}/></Input><br/>
            <Input fluid>
              <DatesRangeInput allowSameEndDate clearable closable iconPosition='right'
                               name='datesRange'
                               placeholder={t('DATE_RANGE')}
                               value={datesRange}
                               onChange={(e, {value}) => {
                                 setDatesRange(value)
                               }}
                               dateFormat={'DD. MM. YYYY'}
              />
            </Input><br/>
            <Input fluid>
              <EnhancedDropdown options={settings.rolesFormatted} selectOptions={setRole}
                                placeholder={t('ROLES')}
              />
            </Input><br/>
            <Input fluid>
              <FormSe.Field>
                <Radio
                  label={t('ALL')}
                  name='se/lo'
                  value={undefined}
                  checked={from === undefined}
                  onChange={handleChangeFrom}
                />
              </FormSe.Field>
              <FormSe.Field>
                <Radio
                  label={t('SERVER')}
                  name='se/lo'
                  value='server'
                  checked={from === 'server'}
                  onChange={handleChangeFrom}
                />
              </FormSe.Field>
              <FormSe.Field>
                <Radio
                  label={t('LOCAL')}
                  name='se/lo'
                  value='local'
                  checked={from === 'local'}
                  onChange={handleChangeFrom}
                />
              </FormSe.Field>
            </Input><br/>

            <hr/><br/>

            <Input fluid>
              <FormSe.Field>
                <Radio
                  label={t('ACTIVE')}
                  name='read'
                  value='0'
                  checked={read === '0'}
                  onChange={handleChangeRead}
                />
              </FormSe.Field>
              <FormSe.Field>
                <Radio
                  label={t('SOLVED')}
                  name='read'
                  value='1'
                  checked={read === '1'}
                  onChange={handleChangeRead}
                />
              </FormSe.Field>
              <FormSe.Field>
                <Radio
                  label={t('ALL')}
                  name='read'
                  value='all'
                  checked={read === 'all'}
                  onChange={handleChangeRead}
                />
              </FormSe.Field>
            </Input><br/>
            <div style={{textAlign: 'center'}}>
            <Button type='submit'>
              {t('SEARCH')}
            </Button>
            </div>
          </Form>
        </Segment>
        <br/><br/>
        <List divided relaxed className='notifications'>
          {(data && data.map) ? (
            data.map(e => <NotificationItem data={e} getListNotifications={getListNotifications}/>)
          ) : (
            <div style={{textAlign: 'center'}}>
              <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
            </div>
          )}
        </List>
        <br/>
      </Container>
    </Segment>
  );
};

export default translate('Notifications')(Notifications);
