import React from 'react';
import {Dropdown} from "semantic-ui-react";

const EnhancedDropdown = ({options, setOptions, selectOptions, placeholder, defaultValue, allowAdditions, additionLabel, multiple, deburr, disabled, className}) => (
    <Dropdown fluid search selection clearable multiple={multiple !== false} deburr={deburr !== false}
              onChange={(e, {value}) => selectOptions(value)}
              placeholder={placeholder}
              options={(options && options.length) ? [...options].sort((a, b) => a.text.localeCompare(b.text)) : []}
              loading={!(options)}
              disabled={!(options) || disabled}
              defaultValue={defaultValue}
              allowAdditions={allowAdditions}
              additionLabel={additionLabel}
              onAddItem={(e, { value }) => setOptions([...options, { key: value, text: value, value }])}
              className={className}
    />
);

export default EnhancedDropdown;
