import * as React from 'react';
import {Button} from "semantic-ui-react";

import EnhancedSearch from "./EnhancedSearch";
import {search2} from "../../modules/searchResults/_actionsSearch";
import {gethypcand} from "../../modules/_actions";
import EnhancedModal from "./EnhancedModal";

const EnhancedSearchWithModal = ({title, what, newSees, setNewSees, withModal, idRequired}) => {
  const [modal, setModal] = React.useState(false);
  const [newElement, setNewElement] = React.useState(false);

  const handleGetHypcand = async () => {
    setModal(true);
    const data = await gethypcand(title);
    setModal({data: data.hyper_cands, id: undefined});
  };

  const handleSelectHypcand = (id, title, entryId) => {
    setNewElement({id: id ? id : entryId, title: title});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newSees && newElement) {
      if ((!idRequired || newElement.id)) {
        const tmp = JSON.parse(JSON.stringify(newSees));
        const newNode = {$: newElement.title};
        if (newElement.id) {
          newNode['@entry-id'] = newElement.id;
        }
        tmp[what].push(newNode);
        setNewSees(tmp);
        setNewElement(null);
      } else {
        alert(idRequired);
      }
    }
  };

  return (
    <>
      <EnhancedSearch
        category
        searchEndpoint={search2}
        icon={false}
        resultSelect={handleSelectHypcand}
        resultSelectOnChange
        valueSetter={newElement ? newElement.title : newElement}
      />
      <div className='termForm-buttons'>
        {withModal && (
          <Button circular icon='search' onClick={(e) => {
            e.preventDefault();
            handleGetHypcand();
          }}/>
        )}
        <Button circular positive icon='checkmark' onClick={handleSubmit}/>
      </div>
      {withModal && (
        <EnhancedModal
          what='hyper'
          handleClick={handleSelectHypcand}
          modal={modal}
          setModal={setModal}
        />
      )}
    </>
  );
};

export default EnhancedSearchWithModal;
