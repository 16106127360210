import React from 'react';
import {translate} from "react-translate";
import {Button} from 'semantic-ui-react';

import SavedBackup from "./SavedBackup";
import Loading from "./Loading";

export const handleActionWithSetSavedBackup = async (setSaved, action, timeout) => {
  setSaved(null);
  var response = await action();
  window.open('/public/tecu?action=showlog&log='+response)
  setSaved(true);
  setTimeout(() => setSaved(undefined), timeout ? timeout : 5000);
};
const EnhancedSubmitButton2 = ({saved, title, positive, onClick, t}) => (
  saved ? <SavedBackup/> : saved === null ? <Loading/> : (
    <Button type="submit" onClick={onClick ? onClick : undefined} positive={positive !== false}>
      {title ? title : t('SAVE')}
    </Button>
  )
);

export default translate('Enhanced')(EnhancedSubmitButton2);
