import * as React from "react";
import {translate} from "react-translate";
import {Input} from "semantic-ui-react";
import {Form, Text, TextArea} from "informed";

import EnhancedSubmitButton from "../components/enhanced/EnhancedSubmitButton";

const TezInfoForm = ({handleSubmit, handleSend, initialValues, saved, t}) => (
  <Form onSubmit={handleSubmit} className='ui form' initialValues={initialValues}>
    {t('NAME')}
    <Input fluid><Text field='name' placeholder={t('NAME')}/></Input><br/>
    {t('NAME_SHORT')}
    <Input fluid><Text field='short_name' placeholder={t('NAME_SHORT')}/></Input><br/>
    {t('URL')}
    <Input fluid><Text field='url' placeholder={t('URL')}/></Input><br/>
    {t('ORG')}
    <Input fluid><Text field='organization' placeholder={t('ORG')}/></Input><br/>
    {t('APPR_ORG')}
    <Input fluid><Text field='poverena_organizace' placeholder={t('APPR_ORG')}/></Input><br/>
    {t('COMMISSION')}
    <Input fluid><Text field='komise' placeholder={t('COMMISSION')}/></Input><br/>
    {t('PERSON')}
    <Input fluid><Text field='contact_person' placeholder={t('PERSON')}/></Input><br/>
    {t('PHONE')}
    <Input fluid><Text field='contact_phone' placeholder={t('PHONE')}/></Input><br/>
    {t('MAIL')}
    <Input fluid><Text field='contact_email' placeholder={t('MAIL')}/></Input><br/>
    {t('DESCRIPTION_FIELD')}
    <TextArea field='descriptioncs' placeholder={t('DESCRIPTION_FIELD')}
              style={{minHeight: '7.5em', display: 'block'}}/><br/>
    {t('DESCRIPTION_FIELD_EN')}
    <TextArea field='descriptionen' placeholder={t('DESCRIPTION_FIELD_EN')}
              style={{minHeight: '7.5em', display: 'block'}}/><br/>
    {t('INFO_FIELD')}
    <TextArea field='infocs' placeholder={t('INFO_FIELD')}
              style={{minHeight: '7.5em', display: 'block'}}/><br/>
    {t('INFO_FIELD_EN')}
    <TextArea field='infoen' placeholder={t('INFO_FIELD_EN')}
              style={{minHeight: '7.5em', display: 'block'}}/><br/>

    {(handleSend && !saved && saved !== null) && (
      <EnhancedSubmitButton saved={saved} positive={false} title={t('SEND')} onClick={handleSend}/>
    )}
    <EnhancedSubmitButton saved={saved}/>
  </Form>
);

export default translate('TezInfoForm')(TezInfoForm);
