import * as React from 'react';
import {translate} from "react-translate";
import {Dropdown, Header, Input} from "semantic-ui-react";
import {Form, Text, Checkbox} from "informed";

import {CENTRAL} from "../../constants";
import Context from "../../Context";
import BasicSettingsPage from "../../modules/settings/BasicSettingsPage";
import BasicSettingsPageCentral from "../../modulesCentral/settings/BasicSettingsPage";
import {save_settings, backup} from "../../modules/_actions";
import {save_settings_central} from "../../modulesCentral/_actionsCentral";
import EnhancedSubmitButton, {handleActionWithSetSaved} from "../../components/enhanced/EnhancedSubmitButton";
import EnhancedSubmitButton2, {handleActionWithSetSavedBackup} from "../../components/enhanced/EnhancedSubmitButton2";

const Tech = ({history, t}) => {
  const {settings, refresh} = React.useContext(Context);

  const [initialValues, setInitialValues] = React.useState(false);
  const [optionSelected, setOptionSelected] = React.useState(false);
  const [saved, setSaved] = React.useState(undefined);

  React.useEffect(() => {
    if (settings.tech) {
      setInitialValues({...settings.tech, show_tree: settings.tech.show_tree === "true"});
      setOptionSelected(settings.tech.backup_time)
    }
  }, [settings]);

  const handleSubmit = async (data) => {
    const newSettings = JSON.parse(JSON.stringify(settings));
    newSettings.tech = data;
    newSettings.tech.backup_time = optionSelected;
    CENTRAL ? (
      handleActionWithSetSaved(setSaved, () => save_settings_central(newSettings)).then(refresh)
    ) : (
      handleActionWithSetSaved(setSaved, () => save_settings(newSettings)).then(refresh)
    );
  };

  const handleBackup = async (data) => {
    let params = "";
    params = params + "&databaze=" + !!data.db;
    params = params + "&aplikace=" + !!data.app;
    params = params + "&logy=" + !!data.logs;
    handleActionWithSetSavedBackup(setSaved, () => backup(params))
  };

  const dowOptions = [
    {key: 'week', text: t('WEEK'), value: 'week'},
    {key: 'day', text: t('DAY'), value: 'day'},
    {key: 'false', text: t('NEVER'), value: 'false'},
  ];

  const RenderBasicSettingsPage = CENTRAL ? BasicSettingsPageCentral : BasicSettingsPage;

  return (
    <RenderBasicSettingsPage history={history} current={CENTRAL ? 1 : 3} saved={saved}>
      {initialValues ? (
        <Form onSubmit={handleSubmit} className='ui form' initialValues={initialValues}>
          {t('BACKUP_PATH')}
          <Input fluid><Text field='backup_path' placeholder={t('BACKUP_PATH')}/></Input><br/>
          {t('BACKUP_URL')}
          <Input fluid><Text field='backup_url' placeholder={t('BACKUP_URL')}/></Input><br/>
          {t('BACKUP_USER')}
          <Input fluid><Text field='backup_user' placeholder={t('BACKUP_USER')}/></Input><br/>
          {t('BACKUP_PASS')}
          <Input fluid><Text type="password" field='backup_pass' placeholder={t('BACKUP_PASS')}/></Input><br/>
          {t('BACKUP_TIME')}
          <Dropdown
            fluid selection options={dowOptions} placeholder={t('BACKUP_TIME')}
            onChange={(e, {value}) => setOptionSelected(value)} defaultValue={initialValues.backup_time}
          /><br/>
          {t('LOG_PATH')}
          <Input fluid><Text field='log_path' placeholder={t('LOG_PATH')}/></Input><br/>
          {t('SMTP_SERVER')}
          <Input fluid><Text field='smtp_server' placeholder={t('SMTP_SERVER')}/></Input><br/>
          {t('SMTP_PORT')}
          <Input fluid><Text field='smtp_port' placeholder={t('SMTP_PORT')}/></Input><br/>
          {t('SMTP_USER')}
          <Input fluid><Text field='smtp_user' placeholder={t('SMTP_USER')}/></Input><br/>
          {t('SMTP_PASS')}
          <Input fluid><Text type="password" field='smtp_pass' placeholder={t('SMTP_PASS')} type='password'/></Input><br/>
          {CENTRAL ? (
            <br/>
          ) : (
            <>
              <div className={'ui slider checkbox'} style={{textAlign: 'left'}}>
                <Checkbox field='show_tree'/>
                <label htmlFor='show_tree'>{t('SHOW_TREE')}</label>
              </div>
              <br/><br/>
            </>
          )}
          <EnhancedSubmitButton saved={saved}/>
        </Form>
      ) : (
        <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
      )}
      <br/><br/><hr/><br/><br/>
      <Form onSubmit={handleBackup} className='ui form'>
        <Header as='h2'>{t('CREATE_BACKUP')}</Header><br/>
        <div style={{display: 'inline-block', textAlign: 'left'}}>
          <div className={'ui slider checkbox'}>
            <Checkbox field='db'/>
            <label htmlFor='db'>{t('DB')}</label>
          </div>
          <br/><br/>
          <div className={'ui slider checkbox'}>
            <Checkbox field='app'/>
            <label htmlFor='app'>{t('APP')}</label>
          </div>
          <br/><br/>
          <div className={'ui slider checkbox'}>
            <Checkbox field='logs'/>
            <label htmlFor='logs'>{t('LOGS')}</label>
          </div>
        </div>
        <br/><br/>

        <EnhancedSubmitButton2 saved={saved} title={t('BACKUP_ACTION')}/>
      </Form>
    </RenderBasicSettingsPage>
  );
};

export default translate('Settings')(Tech);
