import React from 'react'
import {translate} from 'react-translate'
import {Container, Header, Icon, Segment, Table} from 'semantic-ui-react'

import {get_deleted} from './_actions'
import Context from '../Context'

const TermDeleted = ({t}) => {
  const {settings} = React.useContext(Context);

  const [rawData, setRawData] = React.useState(undefined);
  const [data, setData] = React.useState(undefined);

  React.useEffect(() => void get_deleted().then(r => setData(r.deleted)), [])


  return (
    <Segment
      textAlign="center"
      style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
      vertical={true}
    >
      <Container text={true}>
        <Header as="h2" icon={true}>
          <Icon name="edit outline"/>
          {t('TERMS_DELETED')}
        </Header><br/><br/>

        <Segment>
          {data ? (
            <Table celled basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('TERMS_DELETED')}</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data.map(e => (
                  <Table.Row>
                    <Table.Cell>
                      {e.term}
                    </Table.Cell>
                    <Table.Cell>
                      <a href={`/public/tecu?action=recover&id=${e.id}`} rel='noopener noreferrer'>{t('RECOVER_TERM')}</a>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em', margin: '2em 0'}}/>
          )}
        </Segment>
      </Container>
    </Segment>
  )
}

export default translate('Term')(TermDeleted)
