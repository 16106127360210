import * as React from 'react';
import {translate} from "react-translate";
import {Input} from "semantic-ui-react";
import {Checkbox, Form, Text} from "informed";

import BasicSettingsPage from "./BasicSettingsPage";
import EnhancedDropdown from "../../components/enhanced/EnhancedDropdown";
import Context from "../../Context";
import {save_settings} from "../_actions";
import getNameFromCode, {allLanguagesForDropdown} from "../../constants/languages";
import EnhancedSubmitButton, {handleActionWithSetSaved} from "../../components/enhanced/EnhancedSubmitButton";
import urlRegex from "url-regex";

let defaultDomains = [];
let defaultTypes = [];
let defaultKinds = [];
let defaultLanguages = [];
let defaultReqTrans = [];
let defaultSkos = [];
let defaultDict = [];

const Dictionary = ({history, t}) => {
  const {settings, refresh} = React.useContext(Context);

  const [domains, setDomains] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [kinds, setKinds] = React.useState([]);
  const [languages, setLanguages] = React.useState([]);
  const [reqTrans, setReqTrans] = React.useState([]);
  const [skos, setSkos] = React.useState([]);
  const [dict, setDict] = React.useState([]);
  const [optionsDomains, setOptionsDomains] = React.useState([]);
  const [optionsTypes, setOptionsTypes] = React.useState([]);
  const [optionsKinds, setOptionsKinds] = React.useState([]);
  const [optionsReqTrans, setOptionsReqTrans] = React.useState([]);
  const [optionsSkos, setOptionsSkos] = React.useState([]);
  const [optionsDict, setOptionsDict] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState(null);
  const [saved, setSaved] = React.useState(undefined);
  const [errors, setErrors] = React.useState([]);

  React.useEffect(() => {
    if (settings && settings.domainsFormatted && settings.typesFormatted) {
      defaultDomains = settings.dict.domains.map(e => e.domain + '|' + e.color + '|' + e.mark);
      setDomains(defaultDomains);
      setOptionsDomains(settings.domainsFormatted);

      defaultTypes = settings.dict.types;
      setTypes(defaultTypes);
      setOptionsTypes(settings.typesFormatted);

      defaultKinds = settings.dict.druhy;
      setKinds(defaultKinds);
      setOptionsKinds(settings.druhyFormatted);

      defaultLanguages = settings.dict.langs;
      setLanguages(defaultLanguages);

      defaultReqTrans = settings.dict.req_trans;
      setReqTrans(defaultReqTrans);
      setOptionsReqTrans(settings.langsFormatted);

      defaultSkos = settings.dict.skos;
      setSkos(defaultSkos);
      setOptionsSkos(settings.skosFormatted);

      defaultDict = settings.dict.dictionaries;
      setDict(defaultDict);
      setOptionsDict(settings.dictFormatted);

      setInitialValues({
        examples: settings.dict.examples,
        example_length: settings.dict.example_length,
        notif_days: settings.dict.notif_days,
        simpleProcess: settings.dict.simpleprocess === "true",
      });
    }
  }, [settings]);

  const handleSubmit = async (data) => {
    if ((skos.every(e => urlRegex({exact: true, strict: false}).test(e)))) {
      setErrors([]);
      const newSettings = JSON.parse(JSON.stringify(settings));
      newSettings.dict.examples = data.examples;
      newSettings.dict.example_length = data.example_length;
      newSettings.dict.notif_days = data.notif_days;
      newSettings.dict.domains = domains.map(e => ({domain: e.split('|')[0], color: e.split('|')[1], mark: e.split('|')[2]}));
      newSettings.dict.types = types;
      newSettings.dict.druhy = kinds;
      newSettings.dict.langs = languages;
      newSettings.dict.req_trans = reqTrans;
      newSettings.dict.skos = skos;
      newSettings.dict.dictionaries = dict;
      newSettings.dict.simpleprocess = data.simpleProcess;
      handleActionWithSetSaved(setSaved, () => save_settings(newSettings)).then(refresh);
    } else {
      const failed = [];
      skos.forEach(e => {
        if (!urlRegex({exact: true, strict: false}).test(e)) failed.push(t('ERROR_DICT_SKOS_NOT_URL') + e);
      });
      setErrors(failed)
    }
  };

  const selectNewAllowedLanguages = (data) => {
    setLanguages(data);
    setOptionsReqTrans(data.map(e => ({key: e, value: e, flag: e, text: getNameFromCode(e)})));
  };

  return (
    <BasicSettingsPage history={history} current={4} saved={saved}>
      {initialValues ? (
        <Form onSubmit={handleSubmit} className='ui form' initialValues={initialValues}>
          {errors.length > 0 && (
            <div className='errors'>
              <p><b>{t('ERRORS_FOUND')}: {errors.length}</b></p>
              {errors.map(e => <p key={e}>{e}</p>)}
              <br/>
            </div>
          )}
          {t('EXAMPLES')}
          <Input fluid><Text field='examples' placeholder={t('EXAMPLES')}/></Input><br/>
          {t('EXAMPLE_LENGTH')}
          <Input fluid><Text field='example_length' type='number' placeholder={t('EXAMPLE_LENGTH')}/></Input><br/>
          {t('DOMAINS')}
          <Input fluid>
            <EnhancedDropdown
              options={optionsDomains}
              selectOptions={setDomains}
              setOptions={setOptionsDomains}
              defaultValue={defaultDomains}
              placeholder={t('DOMAINS')}
              allowAdditions
              additionLabel={t('ADD_TO_DROPDOWN')}
            />
          </Input><br/>
          {t('TYPES')}
          <Input fluid>
            <EnhancedDropdown
              options={optionsTypes}
              selectOptions={setTypes}
              setOptions={setOptionsTypes}
              defaultValue={defaultTypes}
              placeholder={t('TYPES')}
              allowAdditions
              additionLabel={t('ADD_TO_DROPDOWN')}
            />
          </Input><br/>
          {t('KINDS')}
          <Input fluid>
            <EnhancedDropdown
              options={optionsKinds}
              selectOptions={setKinds}
              setOptions={setOptionsKinds}
              defaultValue={defaultKinds}
              placeholder={t('KINDS')}
              allowAdditions
              additionLabel={t('ADD_TO_DROPDOWN')}
            />
          </Input><br/>
          {t('LANGUAGES')}
          <Input fluid>
            <EnhancedDropdown
              options={allLanguagesForDropdown}
              selectOptions={selectNewAllowedLanguages}
              defaultValue={defaultLanguages}
              placeholder={t('LANGUAGES')}
            />
          </Input><br/>
          {t('REQ_TRANS')}
          <Input fluid>
            <EnhancedDropdown
              options={optionsReqTrans}
              selectOptions={setReqTrans}
              defaultValue={defaultReqTrans}
              placeholder={t('REQ_TRANS')}
            />
          </Input><br/>
          {t('SKOS')}
          <Input fluid>
            <EnhancedDropdown
              options={optionsSkos}
              selectOptions={setSkos}
              setOptions={setOptionsSkos}
              defaultValue={defaultSkos}
              placeholder={t('SKOS')}
              allowAdditions
              additionLabel={t('ADD_TO_DROPDOWN')}
            />
          </Input><br/>
          {t('DICTIONARIES')}
          <Input fluid>
            <EnhancedDropdown
              options={optionsDict}
              selectOptions={setDict}
              defaultValue={defaultDict}
              placeholder={t('DICTIONARIES')}
            />
          </Input><br/>
          {t('NOTIFICATION_AFTER_DAYS')}
          <Input fluid><Text field='notif_days' type='number' placeholder={t('NOTIFICATION_AFTER_DAYS')}/></Input><br/>
          {t('APPROVAL_PROCESS')}
          <div style={{lineHeight: '2em'}}>
            {t('COMPLEX')}
            <div style={{display: 'inline-block', verticalAlign: 'sub', padding: '1em'}}>
              <div className='ui checked fitted slider checkbox'>
                <Checkbox field={'simpleProcess'} slider/>
                <label/>
              </div>
            </div>
            {t('SIMPLE')}
          </div>

          <EnhancedSubmitButton saved={saved}/>
        </Form>
      ) : (
        <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
      )}
    </BasicSettingsPage>
  )
};

export default translate('Settings')(Dictionary);
