import * as React from 'react';
import {translate} from "react-translate";
import {Search} from "semantic-ui-react";
import _ from 'lodash';

import {ID_PART_OF_TITLE_DELIMITER} from '../../constants';

const EnhancedSearch = ({category, searchEndpoint, currentLang, resultSelect, resultSelectOnChange, icon, valueSetter, customInput, t}) => {
    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState([]);
    const [value, setValue] = React.useState('');

    React.useEffect(() => {
        if (valueSetter) {
            setValue(valueSetter);
        } else if (valueSetter === null) {
            setValue('');
        }
    }, [valueSetter]);

    const resetComponent = () => {
        setLoading(false);
        setResults([]);
        setValue('');
    };

    const handleResultSelect = (e, {result}) => {
        setValue(result.title.split(ID_PART_OF_TITLE_DELIMITER)[0]);
        resultSelect && resultSelect(result.id, result.title.split(ID_PART_OF_TITLE_DELIMITER)[0]);
    };

    const handleSearchChange = (e, {value}) => {
        resultSelectOnChange && resultSelect(null, value);
        if (value.length < 1) resetComponent();
        else {
            setValue(value);
            handelSearch(value.length > 2 ? value : null);
        }
    };

    const handelSearch = async (value) => {
        if (value === null) {
            setResults({error: {name: t('ERROR'), results: [{title: t('AT_LEAST_3')}]}});
        } else {
            setLoading(true);
            const res = await searchEndpoint(value, currentLang === 'cs' ? 'cz' : currentLang === 'en' ? 'gb' : currentLang, true);
            try {
                Object.keys(res).forEach(k => (res[k].results.forEach(r => r.title = r.title + ID_PART_OF_TITLE_DELIMITER + r.id)));
            } catch (e) {
                console.warn('EnhancedSearch error (probably API changed): ' + e);
            }
            setResults(res);
            setLoading(false);
        }
    };

    const resultRenderer = ({title}) => <p>{title.split(ID_PART_OF_TITLE_DELIMITER)[0]}</p>;

    return (
        <Search
            category={category}
            loading={loading}
            onResultSelect={handleResultSelect}
            onSearchChange={_.debounce(handleSearchChange, 500, {leading: true})}
            resultRenderer={resultRenderer}
            results={results}
            value={value}
            icon={icon}
            placeholder={t('SEARCH_DOT')}
            input={customInput ? customInput(value, t('SEARCH_DOT')) : undefined}
        />
    );
};

export default translate('Search')(EnhancedSearch);
