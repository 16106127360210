import * as React from 'react'

import BasicSettingsPage from './BasicSettingsPage'
import {get_users, save_user, delete_user} from '../_actionsCentral'
import UsersUniversal from '../../modulesUniversal/settings/Users'

const Users = ({history}) => (
  <UsersUniversal
    BasicSettingsPage={BasicSettingsPage}
    current={2}
    get_users={get_users}
    save_user={save_user}
    delete_user={delete_user}
    history={history}
  />
)

export default Users
