import * as React from 'react';
import {translate} from "react-translate";

import BasicSettingsPage from "./BasicSettingsPage";
import {ie} from "../_actions";

const ImportExport = ({history}) => {
  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    (async () => setData((await ie())))();
  }, []);

  return (
    <BasicSettingsPage history={history} current={6}>
      {data ? (
        <div dangerouslySetInnerHTML={data} style={{textAlign: 'left'}}/>
      ) : (
        <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em', marginTop: '2.5em'}}/>
      )}
    </BasicSettingsPage>
  );
};

export default translate('Settings')(ImportExport);
