import * as React from 'react';
import {Icon} from "semantic-ui-react";

import AccordionChild from "./AccordionChild";

const Accordion = ({data, id_prop, path, onClick}) => {
    const shouldOpened = () => path.includes(data.id.toString());

    const [pathState, setPathState] = React.useState(path);
    const [visible, setVisible] = React.useState(shouldOpened());

    React.useEffect(() => {
        if (path !== pathState) {
            setPathState(path);
            if (!visible) {
                setVisible(shouldOpened());
            }
        }
    }, [path]);

    return (
        <>
            <div className='acc-heading'>
                <Icon name='angle right' rotated={visible ? 'clockwise' : null} onClick={() => setVisible(!visible)}/>
                <a onClick={(v) => onClick(v, data.id)} href={'/public/tezaurus/term/' + data.id} className={data.id === id_prop ? 'current' : undefined}>
                    {data.head}
                </a>
            </div>
            {visible && <div>
                {data.expandable.map((e, i) => (
                   <AccordionChild  key={'termSide' + i + '_' + id_prop} e={e} i={i} id_prop={id_prop} path={path} onClick={onClick}/>
                ))}
            </div>}
        </>
    );
};

export default (Accordion);
