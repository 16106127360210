import * as React from 'react';
import {translate} from "react-translate";
import {Container, Header, Icon, Segment} from 'semantic-ui-react';

import {save_user} from "../modules/_actions";
import {save_user as save_user_central} from "../modulesCentral/_actionsCentral.js";
import UserEditForm from "./UserEditForm";
import ContextUser from "../ContextUser";
import {handleActionWithSetSaved} from "../components/enhanced/EnhancedSubmitButton";
import {CENTRAL} from './../constants';

const UserEdit = ({t}) => {
    const {loggedUser} = React.useContext(ContextUser);

    const [initialValues, setInitialValues] = React.useState(null);
    const [roles, setRoles] = React.useState([]);
    const [saved, setSaved] = React.useState(undefined);

    React.useEffect(() => {
        if (loggedUser && loggedUser.roles) {
            setInitialValues(loggedUser);
            setRoles(loggedUser.roles);
        }
    }, [loggedUser]);

    const handleSubmit = async (data) => {
        data.roles = roles;
        handleActionWithSetSaved(setSaved, () => CENTRAL ? save_user_central(data) : save_user(data));
    };

    return (
        <Segment
            textAlign="center"
            style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
            vertical={true}
        >
            <Container text={true}>
                {saved ? (
                    <Header as="h2" icon={true} style={{marginBottom: 0, color: 'green'}}>
                        <Icon name="save outline"/>
                        {t('SAVED')}
                    </Header>
                ) : (
                    <Header as="h2" icon={true} style={{marginBottom: 0}}>
                        <Icon name="user"/>
                        {t('SETTINGS')}
                    </Header>
                )}
                <Header as="h6" style={{marginTop: 0}}>
                    {initialValues ? (
                      initialValues.name
                    ) : (
                        <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '1em'}}/>
                    )}
                </Header>
                <br/>

                {initialValues ? (
                    <UserEditForm
                        handleSubmit={handleSubmit}
                        initialValues={initialValues}
                        setRoles={setRoles}
                        saved={saved}
                    />
                ) : (
                    <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
                )}
            </Container>
        </Segment>
    )
};

export default translate('UserEdit')(UserEdit);
