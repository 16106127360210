import * as React from 'react';
import {translate} from "react-translate";
import {Header, Icon, Input, Segment} from 'semantic-ui-react';
import {ArrayField, TextArea} from "informed";

import {TextAreaH} from "../../components/informedHighlight";

const Pictures = ({edit, entry, newTerm, changed = [], t}) => (
  <>
    <Header as='h3' attached='top'>
      <Icon name='paint brush'/>{t(!edit ? 'PICS' : 'PICS_EDIT')}
    </Header>

    {!edit ? (
      <Segment attached='bottom'>
        {entry.imgs.img.map((e, i) => (
          <div key={'pic' + i}>
            <a href={e.$} target='_blank' rel='noopener noreferrer'><img src={e.$} alt={e.$} style={{maxWidth: '650px'}}/></a>
          </div>
        ))}
      </Segment>
    ) : (
      <Segment attached='bottom' className='termForm-meaning'>
        {!newTerm && entry.imgs && entry.imgs.img.map((e, i) => (
          <div key={'pic' + i} className={(changed === null || (changed && changed[i] === null)) && 'changedDiff-NewBlock'}>
            <Input>{i + 1}. <div className="ui form">
              <TextAreaH field={'pic$' + i}/>
              {changed && changed[i] && <p className='changedDiff'>{changed[i].$}</p>}
            </div></Input>
          </div>
        ))}
        <ArrayField field='picNew'>
          {({add, fields}) => (
            <>
              {fields.map((e, i) => (
                <div key={e.key}>
                  <Input>{((entry && entry.imgs) ? entry.imgs.img.length : 0) + i + 1}.&nbsp;
                    <div className="ui form"><TextArea field={fields[i].field} className={'hgltd'}/></div>
                  </Input>
                </div>
              ))}

              <Icon onClick={add} name='add circle' className='termForm-addCircle'/>
            </>
          )}
        </ArrayField>
      </Segment>
    )}
  </>
);

export default translate('Term')(Pictures);
