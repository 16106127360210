import * as React from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import {Icon} from "semantic-ui-react";

const findFirstDiffPos = (a, b) => {
  let shorterLength = Math.min(a.length, b.length);

  for (let i = 0; i < shorterLength; i++) {
    if (a[i] !== b[i]) return i;
  }

  if (a.length !== b.length) return shorterLength;

  return -1;
}


const EnhancedKeyboard = () => {
  const [visible, setVisible] = React.useState(false);
  const [layoutName, setLayoutName] = React.useState('cz_default');
  const [red, setRed] = React.useState('{cz}');
  const [highlight, setHighlight] = React.useState('');
  const [input, setInput] = React.useState('');
  const [lang, setLang] = React.useState('cz');
  const inputRef = React.useRef();
  const positionCursor = React.useRef(0);

  const onChange = React.useCallback(input => {
    setInput(old => {
      positionCursor.current = (old.length < input.length ? 1 : 0) + findFirstDiffPos(old, input)
      return input
    })
  }, []);

  const onBlur = React.useCallback(_ => {
    inputRef.current.focus()
    inputRef.current.selectionStart = positionCursor.current
    inputRef.current.selectionEnd = positionCursor.current
  }, [])

  const onKeyPress = (button) => {
    setHighlight(button);
    if (['{cz}', '{sk}', '{eng}', '{azb}', '{fran}', '{ger}', '{greek}'].indexOf(button) !== -1) {
      const newLang = button.slice(1, button.length - 1);
      const currentLayout = layoutName.split('_')[1];
      if (currentLayout === 'default') setRed(button);
      else setRed(button + ' {' + currentLayout + '}');
      setLang(newLang);
      setLayoutName(newLang + '_' + currentLayout);
    } else if (button === '{shift}') {
      if (red.indexOf('{shift}') !== -1) {
        setLayoutName(lang + '_default');
        setRed('{' + lang + '}');
      } else {
        setLayoutName(lang + '_shift');
        setRed('{' + lang + '} {shift}');
      }
    } else if (button === '{lock}') {
      if (red.indexOf('{lock}') !== -1) {
        setLayoutName(lang + '_default');
        setRed('{' + lang + '}');
      } else {
        setLayoutName(lang + '_lock');
        setRed('{' + lang + '} {lock}');
      }
    } else if (button === '{num}') {
      if (red === '{num}') {
        setLayoutName(lang + '_default');
        setRed('{' + lang + '}');
      } else {
        setLayoutName('num');
        setRed('{num}');
      }
    } else {
      if (red.indexOf('{shift}') !== -1) {
        setLayoutName(lang + '_default');
        setRed('{' + lang + '}');
      }
    }
  };

  return (
    <div id='virtualKeyboard'>
      <div id='virtualKeyboardButton'>
        <Icon name='keyboard outline' onClick={() => setVisible(!visible)}/>
      </div>

      <div id='virtualKeyboardDiv' className={visible ? 'visible' : undefined}>
        <div>
          <div>
            <div>
              <input ref={inputRef} value={input} onBlur={onBlur}/>

              <Keyboard
                onChange={onChange}
                onKeyPress={onKeyPress}
                theme={"hg-theme-default hg-layout-default"}
                autoUseTouchEvents={true}
                display={{
                  '{bksp}': '<- backspace',
                  '{enter}': 'Enter',
                  '{tab}': 'Tab',
                  '{lock}': 'Caps lock',
                  '{shift}': 'Shift',
                  '{num}': 'Num lock',
                  '{space}': ' ',
                  '{cz}': 'Czech',
                  '{sk}': 'Slovak',
                  '{eng}': 'English',
                  '{azb}': 'Azbuka',
                  '{fran}': 'French',
                  '{ger}': 'German',
                  '{greek}': 'Greek',
                }}
                layoutName={layoutName}
                layout={{
                  cz_default: [
                    '; + ě š č ř ž ý á í é = {bksp}',
                    '{tab} q w e r t z u i o p ú )',
                    '{lock} a s d f g h j k l ů § ¨',
                    '{shift} \\ y x c v b n m , . - {num}',
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  cz_shift: [
                    '° 1 2 3 4 5 6 7 8 9 0 % {bksp}',
                    '{tab} Q W E R T Y U I O P / (',
                    '{lock} A S D F G H J K L " ! \'',
                    '{shift} | Z X C V B N M < > ? {num}',
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  cz_lock: [
                    '; + Ě Š Č Ř Ž Ý Á Í É = {bksp}',
                    '{tab} Q W E R T Y U I O P Ú (',
                    '{lock} A S D F G H J K L Ů ! \'',
                    '{shift} | Z X C V B N M < > ? {num}',
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  sk_default: [
                    "\u010f \u013a \u013e \u0161 \u010d \u0165 \u017e \u00fd \u00e1 \u00ed \u00e9 = {bksp}",
                    "{tab} q w e r t z u i o p \u00fa \u00e4 \u0148",
                    "{lock} a s d f g h j k l \u00f4 \u0142",
                    "{shift} & y x c v b n m \xf3 \u0155 / {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',

                  ],
                  sk_shift: [
                    "\u00b0 1 2 3 4 5 6 7 8 9 0 %  {bksp}",
                    "{tab} Q W E R T Z U I O P / ( )",
                    "{lock} A S D F G H J K L \u0141 !",
                    "{shift} * Y X C V B N M ? : / {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  sk_lock: [
                    "\u00b0 1 2 3 4 5 6 7 8 9 0 %  {bksp}",
                    "{tab} Q W E R T Z U I O P / ( )",
                    "{lock} A S D F G H J K L \u0141 !",
                    "{shift} * Y X C V B N M ? : / {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  eng_default: [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "{tab} q w e r t y u i o p [ ] \\",
                    "{lock} a s d f g h j k l ; '",
                    "{shift} z x c v b n m , . / {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  eng_shift: [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "{tab} Q W E R T Y U I O P { } |",
                    '{lock} A S D F G H J K L : "',
                    "{shift} Z X C V B N M < > ? {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  eng_lock: [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "{tab} Q W E R T Y U I O P { } |",
                    '{lock} A S D F G H J K L : "',
                    "{shift} Z X C V B N M < > ? {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  azb_default: [
                    "\u0451 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "{tab} \u0439 \u0446 \u0443 \u043a \u0435 \u043d \u0433 \u0448 \u0449 \u0437 \u0445 \u044a \\",
                    "{lock} \u0444 \u044b \u0432 \u0430 \u043f \u0440 \u043e \u043b \u0434 \u0436 \u044d",
                    "{shift} \\ \u044f \u0447 \u0441 \u043c \u0438 \u0442 \u044c \u0431 \u044e / {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  azb_shift: [
                    '\u0401 ! " \u2116 ; % : ? * ( ) _ + {bksp}',
                    "{tab} \u0419 \u0426 \u0423 \u041a \u0415 \u041d \u0413 \u0428 \u0429 \u0417 \u0425 \u042a /",
                    "{lock} \u0424 \u042b \u0412 \u0410 \u041f \u0420 \u041e \u041b \u0414 \u0416 \u042d",
                    "{shift} / \u042f \u0427 \u0421 \u041c \u0418 \u0422 \u042c \u0411 \u042e / {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  azb_lock: [
                    '\u0401 ! " \u2116 ; % : ? * ( ) _ + {bksp}',
                    "{tab} \u0419 \u0426 \u0423 \u041a \u0415 \u041d \u0413 \u0428 \u0429 \u0417 \u0425 \u042a /",
                    "{lock} \u0424 \u042b \u0412 \u0410 \u041f \u0420 \u041e \u041b \u0414 \u0416 \u042d",
                    "{shift} / \u042f \u0427 \u0421 \u041c \u0418 \u0422 \u042c \u0411 \u042e / {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  fran_default: [
                    "\u00B2 & \u00E9 \" ' ( - \u00E8 _ \u00E7 \u00E0 ) = {bksp}",
                    "{tab} a z e r t y u i o p ^ $",
                    "{lock} q s d f g h j k l m \u00F9 *",
                    "{shift} < w x c v b n , ; : ! {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  fran_shift: [
                    "{//} 1 2 3 4 5 6 7 8 9 0 \u00B0 + {bksp}",
                    "{tab} A Z E R T Y U I O P \u00A3",
                    "{lock} Q S D F G H J K L M % \u00B5",
                    "{shift} > W X C V B N ? . / \u00A7 {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  fran_lock: [
                    "{//} 1 2 3 4 5 6 7 8 9 0 \u00B0 + {bksp}",
                    "{tab} A Z E R T Y U I O P \u00A3",
                    "{lock} Q S D F G H J K L M % \u00B5",
                    "{shift} > W X C V B N ? . / \u00A7 {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  ger_default: [
                    "^ 1 2 3 4 5 6 7 8 9 0 {bksp}",
                    "{tab} q w e r t z u i o p \u00FC +",
                    "{lock} a s d f g h j k l \u00F6 \u00E4 #",
                    "{shift} < y x c v b n m , . - {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  ger_shift: [
                    '\u00B0 ! " \u00A7 $ % & / ( ) = ? {bksp}',
                    "{tab} Q W E R T Z U I O P \u00DC *",
                    "{lock} A S D F G H J K L \u00D6 \u00C4 '",
                    "{shift} > Y X C V B N M ; : _ {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  ger_lock: [
                    '\u00B0 ! " \u00A7 $ % & / ( ) = ? {bksp}',
                    "{tab} Q W E R T Z U I O P \u00DC *",
                    "{lock} A S D F G H J K L \u00D6 \u00C4 '",
                    "{shift} > Y X C V B N M ; : _ {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  greek_default: [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "{tab} ; \u03C2 \u03B5 \u03C1 \u03C4 \u03C5 \u03B8 \u03B9 \u03BF \u03C0 [ ]",
                    "{lock} \u03B1 \u03C3 \u03B4 \u03C6 \u03B3 \u03B7 \u03BE \u03BA \u03BB \u0384 ' \\",
                    "{shift} < \u03B6 \u03C7 \u03C8 \u03C9 \u03B2 \u03BD \u03BC , . / {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  greek_shift: [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "{tab} : \u0385 \u0395 \u03A1 \u03A4 \u03A5 \u0398 \u0399 \u039F \u03A0 { }",
                    "{lock} \u0391 \u03A3 \u0394 \u03A6 \u0393 \u0397 \u039E \u039A \u039B \u00A8 \" |",
                    "{shift} > \u0396 \u03A7 \u03A8 \u03A9 \u0392 \u039D \u039C < > ? {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  greek_lock: [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "{tab} : \u0385 \u0395 \u03A1 \u03A4 \u03A5 \u0398 \u0399 \u039F \u03A0 { }",
                    "{lock} \u0391 \u03A3 \u0394 \u03A6 \u0393 \u0397 \u039E \u039A \u039B \u00A8 \" |",
                    "{shift} > \u0396 \u03A7 \u03A8 \u03A9 \u0392 \u039D \u039C < > ? {num}",
                    '@ {space}',
                    '{cz} {sk} {eng} {azb} {fran} {ger} {greek}',
                  ],
                  num: [
                    '{num}',
                    '/ * - +',
                    '7 8 9',
                    '4 5 6',
                    '1 2 3',
                    '0 , .',
                  ],
                }}
                buttonTheme={[
                  {
                    class: 'hg-red',
                    buttons: red ? red : '{none}'
                  }, {
                    class: 'hg-highlight',
                    buttons: highlight ? highlight : '{none}'
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnhancedKeyboard
