import * as React from 'react';
import {translate} from "react-translate";
import {Container, Header, Icon, Input, Segment} from "semantic-ui-react";
import {Form, Text, TextArea} from "informed";

import ContextUser from "../ContextUser";
import History from "../history";
import EnhancedSubmitButton, {handleActionWithSetSaved} from "../components/enhanced/EnhancedSubmitButton";
import {send_remark} from "./_actions";

const Remark = ({match, t}) => {
  const {loggedUser, loading} = React.useContext(ContextUser);
  const template = match.params.template;
  const [saved, setSaved] = React.useState(false);

  const handleSubmit = async data => handleActionWithSetSaved(setSaved, () => send_remark(data)).then(History.push('/public/tezaurus'));

  const initialValues = {
    text: template ? '#' + template + '\n\n' : undefined,
    subject: template ? '#' + template : undefined,
    name: loggedUser.name && loggedUser.name !== "ERROR" ? loggedUser.name : undefined,
    email: loggedUser.email ? loggedUser.email : undefined,
  }

  return (
    <Segment
      className='settings-segment'
      textAlign="center"
      vertical={true}
    >
      <Container text={true}>
        {saved ? (
          <Header as="h2" icon={true}
                  style={{color: 'green'}}>
            <Icon name="save outline"/>
            {t('SAVED')}
          </Header>
        ) : (
          <Header as="h2" icon={true}>
            {saved === null ? <Icon name="spinner loading icon" style={{opacity: 0.4}}/> :
              <Icon name="mail outline"/>}
            {t('REMARK')}
          </Header>
        )}
        <br/>
        <br/>
        {!loading ? (
          <Form onSubmit={handleSubmit} className='ui form' initialValues={initialValues}>
            {t('NAME')}
            <Input fluid><Text required field='name' placeholder={t('NAME')}/></Input><br/>
            {t('EMAIL')}
            <Input fluid><Text required field='email' placeholder={t('EMAIL')} type='email'/></Input><br/>
            {t('TEXT')}
            <TextArea required field='text' placeholder={t('TEXT')}
                      style={{minHeight: '7.5em', display: 'block'}}/><br/>
            <Input style={{display: 'none'}}><Text field='subject' render={()=>null} /></Input>

            <EnhancedSubmitButton saved={saved} title={t('SEND')}/>
          </Form>
        ) : (
          <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
        )}
      </Container>
    </Segment>
  );
};

export default translate('Remark')(Remark);
