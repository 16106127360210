import * as React from 'react';
import {translate} from "react-translate";
import {Header, Icon, Segment, Label} from 'semantic-ui-react';

const Domains = ({entry, t}) => (
  <>
    <Header as='h3' attached='top'>
      <Icon name='map signs'/>{t('DOMAINS')}
    </Header>
    <Segment attached='bottom'>
      {entry.domains && entry.domains.dom && entry.domains.dom.map((e, i) => (
        <p key={'dom' + i}><Label style={{color: 'white', background: '#' + e['@color']}}>{e['@mark']}</Label> {e.$}</p>
      ))}
    </Segment>
  </>
);

export default translate('Term')(Domains);
