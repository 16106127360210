import * as React from 'react';
import {Link} from 'react-router-dom';
import {translate} from "react-translate";
import {Container, Header, Icon, Segment, List, Input, Button} from 'semantic-ui-react';
import {Form, Text} from "informed";

import {search2, searchAll} from './searchResults/_actionsSearch';
import History from "../history";

const createNewTermRedirect = () => {
  window.scrollTo(0, 0)
  History.push('/public/tezaurus/termEdit?new=' + createNewTermRedirect.term);
}

const TermAdd = ({t}) => {
  const [dataSearch, setDataSearch] = React.useState(undefined);
  const [dataExists, setDataExists] = React.useState(null);

  React.useEffect(() => {
    if (dataExists) {
      setDataSearch(undefined);
    }
  }, [dataExists]);

  const searchTez = async (e) => {
    e.preventDefault()
    const value = document.getElementsByClassName('termTez')[0].value;
    if (value) {
      setDataExists(null);
      setDataSearch(null);
      setDataSearch(await searchAll(value));
    }
  };

  const createNewTerm = ({term}) => {
    setDataExists(undefined)

    search2(term)
      .then(({terminologicky: {results: res}}) => {
        createNewTermRedirect.term = term

        if (res.length) {
          const termObj = res.filter(e => e.title === term)[0]
          if (termObj) {
            if (window.confirm(t('ERR_EXISTS', {term}))) {
              History.push('/public/tezaurus/term/' + termObj.id)
            } else {
              setDataExists(null)
            }
          } else {
            setDataExists(res)
          }
        } else {
          createNewTermRedirect()
        }
      })
  };

  return (
    <Segment
      textAlign="center"
      style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
      vertical={true}
    >
      <Container text={true}>
        <Header as="h2" icon={true}>
          <Icon name="add square"/>
          {t('ADD_TERM')}
        </Header>
        <br/>
        <Form onSubmit={createNewTerm}>
          <Input fluid><Text className='termTez' field='term' placeholder={t('TERM')} required/></Input><br/>
          <Button onClick={searchTez}>
            {t('SEARCH_TEZ')}
          </Button>
          <Button animated type='submit'>
            <Button.Content visible>
              {t('CREATE')}
            </Button.Content>
            <Button.Content hidden>
              {dataExists === undefined ? <Icon loading name="spinner"/> : <Icon name='arrow right'/>}
            </Button.Content>
          </Button>
          {dataExists && (
            <Segment style={{marginTop: '3em'}}>
              <h3>{t('ERR_SIMILAR_TERMS')}</h3>

              <List link style={{textAlign: 'left'}}>
                {dataExists.map(e => (
                  <List.Item as={Link} to={'/public/tezaurus/term/' + e.id}>
                    {e.title}
                  </List.Item>
                ))}
              </List>

              <Button positive onClick={createNewTermRedirect}>{t('ERR_SIMILAR_TERMS_CONTINUE')}</Button>
            </Segment>
          )}
          {dataSearch !== undefined && (
            <Segment style={{marginTop: '3em'}}>
              <List link style={{textAlign: 'left'}}>
                {dataSearch && dataSearch.length ? (
                  dataSearch.map((e) => (
                    <List.Item as='a' href={e.url} target='_blank' key={e.thes_id.toString() + e.term_id}>
                      <List.Header><i>{e.thes_id}</i> – {e.term}</List.Header>
                      {e.definition}
                    </List.Item>
                  ))
                ) : dataSearch === null ? (
                  <div style={{opacity: 0.4, fontSize: '3em', textAlign: 'center', padding: '1em 0'}}>
                    <Icon loading name="spinner"/>
                  </div>
                ) : (
                  t('NO_RESULTS')
                )}
              </List>
            </Segment>
          )}
        </Form>
      </Container>
    </Segment>
  );
};

export default translate('TermAdd')(TermAdd);
