import * as React from 'react'
import {translate} from 'react-translate'
import {Button, Icon, Segment, Table, Popup, Modal} from 'semantic-ui-react'

import UserEditForm from '../UserEditForm'
import Context from '../../Context'

const Users = ({BasicSettingsPage, current, get_users, save_user, delete_user, history, t}) => {
  const {settings} = React.useContext(Context)

  const [users, setUsers] = React.useState(undefined)
  const [modal, setModal] = React.useState(false)
  const [roles, setRoles] = React.useState([])

  React.useEffect(() => {
    if (settings.rolesFormatted) {
      get_users().then(response => {
        const rolesDictionary = {}
        settings.rolesFormatted.forEach(e => rolesDictionary[e.key] = e.text)
        response.users.forEach(user => user.rolesFormatted = user.roles.map(role => rolesDictionary[role]).filter(e => !!e))
        setUsers(response.users)
      })
    }
  }, [modal, settings.rolesFormatted])

  const handleSubmit = async (data, newUser) => {
    if (roles.length === 0) {
      alert(t("REQUIRED_ONE_USER_ROLE"))
    } else {
      data.roles = roles;
      (await save_user(data, newUser))
      setModal(false)
    }
  }

  const deleteUser = async (login) => {
    if (window.confirm(t('SURE'))) {
      await delete_user(login)
      setModal(false)
    }
  }

  const PopupRoles = ({roles}) => (
    <Popup
      content={<>
        <b>{t('LIST_OF_RIGHTS')}:</b>
        {roles.map(e => <p key={e} style={{margin: '.5em 0 0'}}>{e}</p>)}
      </>}
      position='top center'
      trigger={<span style={{textDecoration: 'underline'}}>{roles.length}</span>}
    />
  )

  return (
    <BasicSettingsPage history={history} current={current}>
      <Button onClick={() => {
        setModal({new: true})
        setRoles([])
      }} icon='edit' style={{display: 'block', margin: '0 auto'}}>
        {t('NEW_USER')}
      </Button>
      {users ? (
        <Segment className='toggleAbleContent'>
          <Table compact style={{border: 'none'}}>
            <Table.Body>
              {users.map(e =>
                <Table.Row key={e.login}>
                  <Table.Cell width={1} style={{textAlign: 'right'}}>
                    <Icon name={e.robot ? 'cogs' : 'user'}/>
                  </Table.Cell>
                  <Table.Cell width={5} style={{paddingLeft: 0}}>
                    <b>{e['name']}</b> <i style={{opacity: .5}}>{e['login']}</i>
                  </Table.Cell>
                  <Table.Cell width={1} style={{paddingRight: 0, textAlign: 'right'}}>
                    <Icon name='mail outline'/>
                  </Table.Cell>
                  <Table.Cell width={4} style={{paddingLeft: 0}}>
                    {e['email']}
                  </Table.Cell>
                  <Table.Cell width={1} style={{paddingRight: 0, textAlign: 'right'}}>
                    <Icon name='address card outline'/>
                  </Table.Cell>
                  <Table.Cell width={1} style={{paddingLeft: 0}}>
                    {e.rolesFormatted && e.rolesFormatted.length > 0 ? (
                      <PopupRoles roles={e.rolesFormatted}/>
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                  <Table.Cell width={1} style={{paddingLeft: 0, textAlign: 'right'}}>
                    <Button onClick={() => {
                      setModal(e)
                      setRoles(e.roles)
                    }} icon='edit' size='mini'/>
                  </Table.Cell>
                </Table.Row>,
              )}
            </Table.Body>
          </Table>
        </Segment>
      ) : (
        <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em', marginTop: '2.5em'}}/>
      )}

      {modal && (!modal.new ? (
        <Modal size={'small'} open onClose={() => setModal(false)} closeOnDimmerClick={false}>
          <Modal.Header>{modal.login + t('EDIT')}</Modal.Header>
          <Modal.Content>
            <UserEditForm
              initialValues={modal}
              handleSubmit={handleSubmit}
              setRoles={setRoles}
            />
            <Button negative onClick={() => deleteUser(modal.login)}
                    style={{float: 'right', position: 'relative', bottom: '2.6em'}}
            >
              {t('DELETE')}
            </Button>
            <Button onClick={() => setModal(false)}
                    style={{float: 'right', position: 'relative', bottom: '2.6em'}}
            >
              {t('CANCEL')}
            </Button>
          </Modal.Content>
        </Modal>
      ) : (
        <Modal size={'small'} open onClose={() => setModal(false)} closeOnDimmerClick={false}>
          <Modal.Header>{t('NEW_USER')}</Modal.Header>
          <Modal.Content>
            <UserEditForm
              initialValues={modal}
              handleSubmit={(data) => handleSubmit(data, true)}
              newUser
              setRoles={setRoles}
            />
            <Button onClick={() => setModal(false)}
                    style={{float: 'right', position: 'relative', bottom: '2.6em'}}
            >
              {t('CANCEL')}
            </Button>
          </Modal.Content>
        </Modal>
      ))}
    </BasicSettingsPage>
  )
}

export default translate('Settings')(Users)
