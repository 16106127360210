export const allLanguagesForDropdown = [
  {key: "al", value: "al", flag: "al", text: "albánština"},
  {key: "am", value: "am", flag: "am", text: "arménština"},
  {key: "az", value: "az", flag: "az", text: "ázerbájdžánština"},
  {key: "by", value: "by", flag: "by", text: "běloruština"},
  {key: "be", value: "be", flag: "be", text: "vlámština"},
  {key: "ba", value: "ba", flag: "ba", text: "bosenština"},
  {key: "bg", value: "bg", flag: "bg", text: "bulharština"},
  {key: "cn", value: "cn", flag: "cn", text: "čínština"},
  {key: "cd", value: "cd", flag: "cd", text: "konžština"},
  {key: "hr", value: "hr", flag: "hr", text: "chorvatština"},
  {key: "cz", value: "cz", flag: "cz", text: "čeština"},
  {key: "dk", value: "dk", flag: "dk", text: "dánština"},
  {key: "ee", value: "ee", flag: "ee", text: "estonština"},
  {key: "fi", value: "fi", flag: "fi", text: "finština"},
  {key: "fr", value: "fr", flag: "fr", text: "francouzština"},
  {key: "ge", value: "ge", flag: "ge", text: "gruzínština"},
  {key: "de", value: "de", flag: "de", text: "němčina"},
  {key: "gr", value: "gr", flag: "gr", text: "řečtina"},
  {key: "gl", value: "gl", flag: "gl", text: "grónština"},
  {key: "hu", value: "hu", flag: "hu", text: "maďarština"},
  {key: "is", value: "is", flag: "is", text: "islandština"},
  {key: "in", value: "in", flag: "in", text: "hindština"},
  {key: "ie", value: "ie", flag: "ie", text: "irština"},
  {key: "il", value: "il", flag: "il", text: "hebrejština"},
  {key: "it", value: "it", flag: "it", text: "italština"},
  {key: "jp", value: "jp", flag: "jp", text: "japonština"},
  {key: "kz", value: "kz", flag: "kz", text: "kazaština"},
  {key: "kg", value: "kg", flag: "kg", text: "kyrgyzština"},
  {key: "la", value: "la", flag: "la", text: "laoština"},
  {key: "lv", value: "lv", flag: "lv", text: "lotyština"},
  {key: "lt", value: "lt", flag: "lt", text: "litevština"},
  {key: "lu", value: "lu", flag: "lu", text: "lucemburština"},
  {key: "mk", value: "mk", flag: "mk", text: "makedonština"},
  {key: "my", value: "my", flag: "my", text: "malajština"},
  {key: "mt", value: "mt", flag: "mt", text: "maltština"},
  {key: "md", value: "md", flag: "md", text: "moldavština"},
  {key: "mn", value: "mn", flag: "mn", text: "mongolština"},
  {key: "me", value: "me", flag: "me", text: "černohorština"},
  {key: "np", value: "np", flag: "np", text: "nepálština"},
  {key: "nl", value: "nl", flag: "nl", text: "nizozemština"},
  {key: "kp", value: "kp", flag: "kp", text: "korejština"},
  {key: "no", value: "no", flag: "no", text: "norština"},
  {key: "ps", value: "ps", flag: "ps", text: "arabština"},
  {key: "pl", value: "pl", flag: "pl", text: "polština"},
  {key: "pt", value: "pt", flag: "pt", text: "portugalština"},
  {key: "ro", value: "ro", flag: "ro", text: "rumunština"},
  {key: "ru", value: "ru", flag: "ru", text: "ruština"},
  {key: "ws", value: "ws", flag: "ws", text: "samojština"},
  {key: "cs", value: "cs", flag: "cs", text: "srbština"},
  {key: "sk", value: "sk", flag: "sk", text: "slovenština"},
  {key: "si", value: "si", flag: "si", text: "slovinština"},
  {key: "so", value: "so", flag: "so", text: "somálština"},
  {key: "es", value: "es", flag: "es", text: "španělština"},
  {key: "se", value: "se", flag: "se", text: "švédština"},
  {key: "sy", value: "sy", flag: "sy", text: "syrština"},
  {key: "tj", value: "tj", flag: "tj", text: "tádžičtina"},
  {key: "th", value: "th", flag: "th", text: "thajština"},
  {key: "tr", value: "tr", flag: "tr", text: "turečtina"},
  {key: "tm", value: "tm", flag: "tm", text: "turkmenština"},
  {key: "ua", value: "ua", flag: "ua", text: "ukrajinština"},
  {key: "gb", value: "gb", flag: "gb", text: "angličtina"},
  {key: "uz", value: "uz", flag: "uz", text: "uzbečtina"},
  {key: "va", value: "va", flag: "va", text: "latina"},
  {key: "vn", value: "vn", flag: "vn", text: "vietnamština"},
];

export const getNameFromCode = (code) => {
  let x = null;
  allLanguagesForDropdown.forEach(e => ((e.key === code) && (x = e.text)));
  return x;
};

export default getNameFromCode;
