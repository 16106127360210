import React, {useState, useEffect, createContext} from 'react';

import * as c from './constants';
import {get_settings} from "./modules/_actions";
import {get_settings_central} from "./modulesCentral/_actionsCentral";
import getNameFromCode from "./constants/languages";

const defaultData = {loading: true, settings: {}};

const formatSetting = (settings) => {
  if (!settings || !settings.roles) return settings;

  const ignoredRoles = ['obor_redaktor', 'obor_revizor', 'prekladatel', 'recenzent_preklad'];
  settings.rolesFormatted = [];
  settings.roles.forEach((e) => {
    if (!ignoredRoles.includes(e.role)) settings.rolesFormatted.push({key: e.role, text: e.label, value: e.role});
  });

  if (!c.CENTRAL) {
    settings.editorsFormatted = [];
    settings.editors.forEach((e) => {
      settings.editorsFormatted.push({key: e.login, text: e.name, value: e.login});
    });

    settings.domainsFormatted = [];
    settings.dict.domains.forEach((e) => {
      settings.domainsFormatted.push({key: (e.domain + '|' + e.color + '|' + e.mark), text: (e.domain + '|' + e.color + '|' + e.mark), value: (e.domain + '|' + e.color + '|' + e.mark)});
    });

    settings.domainsFormattedText = [];
    settings.dict.domains.forEach((e) => {
      settings.domainsFormattedText.push({key: e.domain, text: e.domain, value: e.domain});
    });

    settings.druhyFormatted = [];
    settings.dict.druhy.forEach((e) => {
      settings.druhyFormatted.push({key: e, text: e, value: e});
    });

    settings.typesFormatted = [];
    settings.dict.types.forEach((e) => {
      settings.typesFormatted.push({key: e, text: e, value: e});
    });

    settings.langsFormatted = [];
    settings.dict.langs.forEach((e) => {
      settings.langsFormatted.push({key: e, value: e, flag: e, text: getNameFromCode(e)});
    });

    settings.skosFormatted = [];
    settings.dict.skos.forEach((e) => {
      settings.skosFormatted.push({key: e, text: e, value: e});
    });

    settings.dictFormatted = [];
    settings.dictionaries.forEach((e) => {
      settings.dictFormatted.push({key: e.url, text: e.label, value: e.url});
    });
  }

  return settings;
};

const Context = createContext(defaultData);

export const Provider = ({children}) => {
  const [value, updateValue] = useState(defaultData);

  const loadData = async () => {
    updateValue({loading: true, settings: {}, refresh: () => loadData(Context)});
    const settings = formatSetting(await (c.CENTRAL ? get_settings_central() : get_settings()));
    updateValue({loading: false, settings: settings, refresh: () => loadData(Context)});
  };

  useEffect(() => {
    loadData(Context);
  }, []);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

export default Context;
