import * as React from 'react';
import {translate} from "react-translate";
import {Button, Header, Icon, Label, Segment, Table} from 'semantic-ui-react';

import {dateAndTimeFormat} from "../../utilsNew/date";

const Meta = ({entry, t}) => {
    const [historyShow, setHistoryShow] = React.useState(false);

    return (
        <>
            <Header as='h3' attached={historyShow ? 'top' : false} block={!historyShow} className='group'
                    style={{transition: 'all .2s ease'}}>
                <div style={{float: 'left'}}>
                    <span style={{opacity: .4}}><Icon name='history'/>{t('HISTORY')}</span>
                </div>
                <div style={{float: 'right'}}>
                    <Button toggle compact size='mini' active={historyShow}
                            onClick={() => setHistoryShow(!historyShow)}>
                        {t('SHOW')}
                    </Button>
                </div>
            </Header>
            {historyShow && (
                <Segment className='toggleAbleContent' attached='bottom'>
                    <Table compact style={{border: 'none'}}>
                        <Table.Body>
                            {entry.notes.note.map((e) => (
                                <Table.Row>
                                    <Table.Cell width={3}>
                                        <Label>
                                            <Icon name='user'/>{e['@author']}
                                        </Label>
                                    </Table.Cell>
                                    <Table.Cell width={1} style={{paddingRight: 0, textAlign: 'right'}}>
                                        <Icon name='clock outline'/>
                                    </Table.Cell>
                                    <Table.Cell width={4} style={{paddingLeft: 0}}>
                                        {dateAndTimeFormat(e['@time'])}
                                    </Table.Cell>
                                    <Table.Cell width={1} style={{paddingRight: 0, textAlign: 'right'}}>
                                        <Icon name='file alternate outline'/>
                                    </Table.Cell>
                                    <Table.Cell width={10} style={{paddingLeft: 0}}>
                                        {e['$']}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
            )}
        </>
    );
};

export default translate('Term')(Meta);
