import * as React from 'react';
import {translate} from "react-translate";
import {Button, Header, Icon, Segment} from 'semantic-ui-react';

const Examples = ({context, t}) => {
    const [contextShow, setContextShow] = React.useState(false);

    return (
        <>
            <Header as='h3' attached={contextShow ? 'top' : false} block={!contextShow}
                    className='group' style={{transition: 'all .2s ease'}}>
                <div style={{float: 'left'}}>
                    <span style={{opacity: .4}}><Icon name='tasks'/>{t('EXAMPLES')}</span>
                </div>
                <div style={{float: 'right'}}>
                    <Button toggle compact size='mini' active={contextShow}
                            onClick={() => setContextShow(!contextShow)}>
                        {t('SHOW')}
                    </Button>
                </div>
            </Header>
            {contextShow && (
                <Segment className='toggleAbleContent' attached='bottom'>
                    {context.map((e, i) => (
                        <div key={'exl' + i}>
                            <p>{i + 1}. {e}</p>
                        </div>
                    ))}
                </Segment>
            )}
        </>
    );
};

export default translate('Term')(Examples);
