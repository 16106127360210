import * as React from 'react';
import {translate} from "react-translate";
import {Header, Icon, Input, Segment} from 'semantic-ui-react';
import {ArrayField, TextArea} from "informed";

import {TextAreaH} from "../../components/informedHighlight";

const Videos = ({edit, entry, newTerm, changed = [], t}) => (
  <>
    <Header as='h3' attached='top'>
      <Icon name='file video outline'/>{t(!edit ? 'VIDS' : 'VIDS_EDIT')}
    </Header>

    {!edit ? (
      <Segment attached='bottom'>
        {entry.videos.video.map((e, i) => (
          <div key={'vid' + i}>
            <a href={e.$} target='_blank' rel='noopener noreferrer'>{e.$}</a>
          </div>
        ))}
      </Segment>
    ) : (
      <Segment attached='bottom' className='termForm-meaning'>
        {!newTerm && entry.videos && entry.videos.video.map((e, i) => (
          <div key={'vid' + i} className={(changed === null || (changed && changed[i] === null)) && 'changedDiff-NewBlock'}>
            <Input>{i + 1}. <div className="ui form">
              <TextAreaH field={'vid$' + i}/>
              {changed && changed[i] && <p className='changedDiff'>{changed[i].$}</p>}
            </div></Input>
          </div>
        ))}
        <ArrayField field='vidNew'>
          {({add, fields}) => (
            <>
              {fields.map((e, i) => (
                <div key={e.key}>
                  <Input>{((entry && entry.videos) ? entry.videos.video.length : 0) + i + 1}.&nbsp;
                    <div className="ui form"><TextArea field={fields[i].field} className={'hgltd'}/></div>
                  </Input>
                </div>
              ))}

              <Icon onClick={add} name='add circle' className='termForm-addCircle'/>
            </>
          )}
        </ArrayField>
      </Segment>
    )}
  </>
);

export default translate('Term')(Videos);
