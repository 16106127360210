import * as React from 'react';
import {Link} from 'react-router-dom';
import {translate} from "react-translate";
import {Container, Header, Icon, Segment, List, Button, Checkbox} from 'semantic-ui-react';

import {search2} from "./_actionsSearch";
import Context from "../../Context";
import LanguageSelect from "../../components/enhanced/LanguageSelect";

const SearchResultsAlphabet = ({match, t}) => {
  const code = match.params.code;
  const {settings} = React.useContext(Context);

  const [data, setData] = React.useState(null);
  const [currentLang, setCurrentLang] = React.useState('cz');
  const [term, setTerm] = React.useState(true);
  const [abbrev, setAbbrev] = React.useState(true);

  React.useEffect(() => {
    if (code) getSearch();
    else setData(null);
  }, [code, term, abbrev, currentLang]);

  const getSearch = async () => {
    setData(undefined);
    setData((await search2(code, currentLang, undefined, !term, !abbrev)).terminologicky.results);
  };

  const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  return (
    <Segment
      textAlign="center"
      style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
      vertical={true}
    >
      <Container text={true}>
        <Header as="h2" icon={true}>
          <Icon name="search"/>
          {t('ALPHABET')}
        </Header><br/><br/>

        {(settings && settings.dict) ? (
          <>
            <LanguageSelect
              languages={settings.dict.langs}
              current={currentLang}
              callback={setCurrentLang}
            />
            <br/>
            <div id='searchAlphabet-controls'>
              <Button.Group compact>
                {(settings.dict.alphabet && settings.dict.alphabet[currentLang]) ? (
                  settings.dict.alphabet[currentLang].map(e => (
                    <Button positive={e === code} compact as={Link} to={'/public/tezaurus/alphabet/' + e}>{e}</Button>
                  ))
                ) : (
                  alphabet.map(e => (
                    <Button positive={e === code} compact as={Link} to={'/public/tezaurus/alphabet/' + e}>{e}</Button>
                  )))}
              </Button.Group>
            </div>

            <div style={{padding: '1em'}}>
              <Checkbox slider onChange={() => setTerm(!term)} checked={term} label={t('BROWSE_TERM')}/>
              <div style={{display: 'inline-block', width: '3em'}}/>
              <Checkbox slider onChange={() => setAbbrev(!abbrev)} checked={abbrev} label={t('BROWSE_ABBR')}/>
            </div>

            {data ? (
              data.length ? (
                <List style={{textAlign: 'left'}} divided relaxed>
                  {data.map(e => (
                    <List.Item key={e.id} as='a' href={'/public/tezaurus/term/' + e.id}
                               target='_blank' rel='noopener noreferrer'>
                      <List.Content>
                        {e.title}
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              ) : (
                t('NO_RESULTS_FOUND')
              )
            ) : (data === undefined && (
              <>
                <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
                <br/>
                {t('LOADING')}
              </>
            ))}
          </>
        ) : (
          <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
        )}
      </Container>
    </Segment>
  );
};

export default translate('SearchResults')(SearchResultsAlphabet);
