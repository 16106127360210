import React, {useState, useEffect, createContext} from 'react';
import queryString from "query-string";

import History from "./history";
import {getdoc} from "./modules/_actions";
import diffJSON from "./utilsNew/diffJSON";

const langsDefInitialState = {newTerms: [], oldTerms: []};
const defaultData = {
  loading: true,
  defaultLang: 'cz',
  initialValues: null,
  id: undefined,
  entry: {},
  title: undefined,
  langsDef: langsDefInitialState,
  langsDefHandlers: {}
};

const ContextInitialValues = createContext(defaultData);

export const handleSelectDefinitionsReducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      return action.payload;
    case 'HANDLE':
      const {option, i, newTerm} = action.payload;
      const tmp = JSON.parse(JSON.stringify(state));
      newTerm ? tmp.newTerms[i] = option.value : tmp.oldTerms[i] = option.value;
      return tmp;
    default:
      throw new Error('Unknown action for reducer \'handleSelectDefinitionsReducer\'.');
  }
};

export const ProviderInitialValues = ({children}) => {
  const [value, updateValue] = useState(defaultData);

  const [langsDef, dispatchLangsDef] = React.useReducer(handleSelectDefinitionsReducer, langsDefInitialState);
  const setLangsDef = data => dispatchLangsDef({type: 'SET', payload: data});
  const handleSelectDefinitions = data => dispatchLangsDef({type: 'HANDLE', payload: data});
  React.useEffect(() => {
    updateValue(v => ({...v, langsDef}));
  }, [langsDef]);

  const newTerm = queryString.parse(History.location.search).new;

  useEffect(() => {
    getEntry(queryString.parse(History.location.search).id);
  }, []);

  const getEntry = async (idQuery) => {
    const tmp = JSON.parse(JSON.stringify(value));

    tmp.id = idQuery;
    let e = undefined;
    let oldE = undefined;
    if (idQuery !== undefined) {
      [e, oldE] = await Promise.all([
        getdoc(idQuery, 'edit'),
        getdoc(idQuery)
      ]);
      e = e.entry;
      oldE = oldE.entry;
    }
    if (!newTerm && e === null) {
      tmp.initialValues = undefined;
    } else {
      const formValues = buildFormValuesFromEntry(e);
      tmp.title = (newTerm ? newTerm : (e.terms.term.length !== 0 && e.terms.term[0].$));
      tmp.entry = e;
      tmp.initialValues = formValues.initialValues;
      tmp.langsDef = formValues.langsDef;
      tmp.langsDefHandlers = {setLangsDef, handleSelectDefinitions};
      if (oldE) tmp.changed = diffJSON(e, oldE);
    }

    updateValue(tmp);
  };

  const buildFormValuesFromEntry = e => {
    if (!e) {
      return {
        initialValues: {trans0: '1', transText0: newTerm, abbrev: false},
        langsDef: langsDef
      };
    }

    const x = {};

    x.abbrev = (e['@abbrev'] === "true" || e['@abbrev'] === true);

    let langsDefTmp = JSON.parse(JSON.stringify(langsDef));
    e.defs && e.defs.def.forEach((e, i) => {
      x['orderNumber' + i] = (i + 1);
      x['def' + i] = (i + 1).toString();
      x['def$' + i] = e.html ? e.html.$ : e.text ? e.text.$ : '';
      x['defSource' + i] = e.zdroj ? e.zdroj.$ : '';
      x['defLaw' + i] = e.law ? e.law.$ : '';
      x['defMath' + i] = e.math ? e.math.$ : '';
      x['defTable' + i] = e.table ? e.table.$ : '';
      x['defObor' + i] = e['@obor'] ? e['@obor'].split('|')[0] : '';
      x['defDruh' + i] = e.druh ? e.druh.$ : '';
      x['defType' + i] = e.type ? e.type.$ : '';
      x['defMoreinfo' + i] = e.moreinfo ? e.moreinfo.$ : '';
      x['defPrivate' + i] = e.private ? e.private.$ : '';

      langsDefTmp.oldTerms.push(e['@lang']);
    });
    setLangsDef(langsDefTmp);

    e.terms && e.terms.term.forEach((e, i) => {
      x['trans' + i] = e['@number'];
      x['transText' + i] = e.$;
      x['transPron' + i] = e['@pron'];
      x['transNote' + i] = e['@note'];
    });

    e.imgs && e.imgs.img.forEach((e, i) => {
      x['pic$' + i] = e.$;
    });

    e.videos && e.videos.video.forEach((e, i) => {
      x['vid$' + i] = e.$;
    });

    e.exlinks && e.exlinks.exlink.forEach((e, i) => {
      x['exl$' + i] = e.$;
    });

    return {
      initialValues: x,
      langsDef: langsDefTmp,
    };
  };

  return (
    <ContextInitialValues.Provider value={value}>
      {children}
    </ContextInitialValues.Provider>
  );
};

export default ContextInitialValues;
