const diffJSON = (newJSONprop, oldJSONprop) => {
  const newJSON = JSON.parse(JSON.stringify(newJSONprop));
  const oldJSON = JSON.parse(JSON.stringify(oldJSONprop));

  const x = Object.keys(newJSON).map(e => {
    if (newJSON[e].map && oldJSON[e] && oldJSON[e].map) {
      const result = diffJSON(newJSON[e], oldJSON[e]);
      return result ? {[e]: result} : false;
    }
    if (typeof newJSON[e] === 'object' && typeof oldJSON[e] === 'object') {
      const result = diffJSON(newJSON[e], oldJSON[e]);
      return result ? {[e]: result} : false;
    }
    return (newJSON[e] === oldJSON[e] ? false : {[e]: oldJSON[e]});
  }).filter(e => e !== false);

  if (x.length === 0) return false;
  const resultJSON = {};
  x.forEach((e, i) => Object.keys(e).forEach(o => resultJSON[o] = x[i][o] === undefined ? null : x[i][o]));

  return resultJSON;
};

export default diffJSON;
