import React from 'react';
import {translate} from "react-translate";
import {Button} from 'semantic-ui-react';

import Saved from "./Saved";
import Loading from "./Loading";

export const handleActionWithSetSaved = async (setSaved, action, timeout) => {
  setSaved(null);
  await action();
  setSaved(true);
  setTimeout(() => setSaved(undefined), timeout ? timeout : 5000);
};

const EnhancedSubmitButton = ({saved, title, positive, onClick, t}) => (
  saved ? <Saved/> : saved === null ? <Loading/> : (
    <Button type="submit" onClick={onClick ? onClick : undefined} positive={positive !== false}>
      {title ? title : t('SAVE')}
    </Button>
  )
);

export default translate('Enhanced')(EnhancedSubmitButton);
