import * as React from 'react';
import {translate} from 'react-translate';
import {Link, NavLink} from 'react-router-dom';
import {Form, Image, Menu, Icon, Button} from 'semantic-ui-react';

import {search2} from "../../modules/searchResults/_actionsSearch";
import * as c from '../../constants';
import {listNotifications} from '../../modules/_actions';
import Search from '../enhanced/EnhancedSearch'
import logo from '../../assets/img/logo.png';
import logoC from '../../assets/img/logo_c.png';
import LanguageSelect from "../enhanced/LanguageSelect";
import History from "../../history";
import Context from "../../Context";
import ContextUser from "../../ContextUser";
import {authorize, removeFromArr} from "../../utilsNew";
import logout from "../../utilsNew/logout";

const Appbar = ({currentLang, setCurrentLang, t}) => {
  const {settings} = React.useContext(Context);
  const {loading, loggedUser} = React.useContext(ContextUser);

  const [notifications, setNotifications] = React.useState([]);

  React.useEffect(() => {
    if (!c.CENTRAL) getListNotifications();
  }, []);

  const getListNotifications = async () => {
    setNotifications(await listNotifications());
  };

  const changeLanguage = (lang) => {
    setCurrentLang(lang);

    //await ...(lang);

  };

  const root = c.CENTRAL ? '/central' : '/public/tezaurus';

  const handleMobileMenuOpener = () => {
    const appBar = document.getElementById('appBar');

    appBar.classList.contains('menuOpened') ? (
      appBar.classList.remove('menuOpened')
    ) : (
      appBar.classList.add('menuOpened')
    );
  };

  const GlobalLanguageSelect = () => <LanguageSelect languages={[{flag: 'cz', lang: 'cs'}, {flag: 'uk', lang: 'en'}]} current={currentLang} callback={changeLanguage}/>;

  const onsubmit = value => History.push('/public/tezaurus/search/' + value);

  return (
    <Menu fixed="top" inverted={true} id="appBar" size="small">
      <Menu.Menu className="appBar_menu">
        {c.CENTRAL ? (
          <Menu.Item as={Link} to={root} header={true} className="logo__item">
            <Image alt={''} src={logoC} className="logo__img"/>
            {t('CENTRAL')}
          </Menu.Item>
        ) : (
          <Menu.Item as={Link} to={root} header={true}>
            <Image alt={''} src={logo} className="logo__img"/>
            {(settings && settings.meta) ? settings.meta['short_name'] : (
              <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '1em'}}/>
            )}
          </Menu.Item>
        )}

        {c.CENTRAL ? (
          <Menu.Item>
            {loggedUser && loggedUser.roles && authorize(['operator', 'it_admin', 'referent'], loggedUser.roles) && (
              <NavLink to={root + '/settings'}>
                <Button>
                  {t('SETTINGS')}
                </Button>
              </NavLink>
            )}
            <GlobalLanguageSelect/>
          </Menu.Item>
        ) : (
          <Menu.Item>
            <Search
              category
              searchEndpoint={search2}
              currentLang={currentLang}
              resultSelect={(id) => History.push(root + '/term/' + id)}
              icon='search'
              customInput={(value, placeholder) => (
                <Form onSubmit={() => onsubmit(value)}>
                  <Form.Input
                    placeholder={placeholder}
                    icon={<Icon name='search' link onClick={() => onsubmit(value)}/>}
                  />
                </Form>
              )}
            />
            <NavLink to={root + '/filter'}>
              <Button style={{marginLeft: '1em'}}>
                {t('FILTER')}
              </Button>
            </NavLink>
            {loggedUser && loggedUser.roles && authorize(['asist_hl', 'tajemnik', 'revizor', 'obor_redaktor', 'obor_revizor'], loggedUser.roles) && (
              <NavLink to={root + '/add'}>
                <Button icon labelPosition='right' style={{marginLeft: '1em'}}>
                  {t('ADD')}
                  <Icon name='plus'/>
                </Button>
              </NavLink>
            )}
            <GlobalLanguageSelect/>
          </Menu.Item>
        )}
      </Menu.Menu>
      <Menu.Menu position="right">
        {loggedUser && loggedUser.roles && authorize(settings.roles && removeFromArr(settings.roles.map(e => e.role), 'host'), loggedUser.roles) ? (
          <Menu.Item>
            {c.CENTRAL ? (
              <Link to={root + '/edit'} style={{position: 'relative', marginRight: '.5em'}}>
                <Icon name="user" size="large"/>
              </Link>
            ) : (
              <Link to={root + '/notifications'} style={{position: 'relative', marginRight: '.5em'}}>
                <Icon name="bell" size="large"/>
                {(notifications && !!notifications.length) && (
                  <span id='notificationsBell-number' className="floating ui red label">
                    {notifications.length}
                  </span>
                )}
              </Link>
            )}
            <div className='appBar_user'>
              <Link to={root + '/edit'}>
                <div>
                  {(loggedUser && loggedUser.name) ? loggedUser.name : (loggedUser.login ? loggedUser.login : t('LOADING'))}
                </div>
              </Link>&nbsp;|&nbsp;<a onClick={logout}>{t('LOGOUT')}</a>
            </div>
          </Menu.Item>
        ) : loading ? (
          <Menu.Item>
            <div className='appBar_user'>
              <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '1em'}}/>
            </div>
          </Menu.Item>
        ) : (
          <Menu.Item>
            <div className='appBar_user'>
              <a href={'https://' + window.location.host + process.env.PUBLIC_URL}>
                {t('LOGIN')}
              </a>
            </div>
          </Menu.Item>
        )}
      </Menu.Menu>

      <div id='mobileMenuOpener' onClick={handleMobileMenuOpener}>
        <span/>
        <span/>
        <span/>
      </div>
    </Menu>
  );
};

export default translate('Appbar')(Appbar);
