import * as React from 'react'
import {ChromePicker} from 'react-color'

const EnhancedColorPicker = ({openedPicker, setOpenedPicker, init, num, fn, text}) => {
  const [color, setColor] = React.useState(init)

  return (
    <>
      <div onClick={() => { openedPicker !== num ? setOpenedPicker(num) : setOpenedPicker(0); }} style={{cursor: 'pointer'}}>
        <span className='colorDot' style={{backgroundColor: color}}> </span><span>{text}</span>
      </div>
      <ChromePicker color={color} onChange={(c) => { setColor(c.hex); fn(c.hex); }}/>

      {openedPicker === num && (
        <div className='colorPickerCloser' onClick={() => setOpenedPicker(0)}/>
      )}
    </>
  )
}

export default (EnhancedColorPicker)
