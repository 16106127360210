import React, {useState, useEffect, createContext} from 'react';
import {get_init} from "./modules/_actions";
import {get_init as get_init_central} from "./modulesCentral/_actionsCentral";
import {CENTRAL} from "./constants"

const defaultData = {loading: true, loggedUser: {}};

const ContextUser = createContext(defaultData);

export const ProviderUser = ({children}) => {
  const [value, updateValue] = useState(defaultData);

  const loadData = async () => {
    updateValue({loading: true, loggedUser: {}, refresh: () => loadData(ContextUser)});
    const loggedUser = (await (CENTRAL ? get_init_central() : get_init())).logged_user[0];
    updateValue({loading: false, loggedUser: loggedUser, refresh: () => loadData(ContextUser)});
  };

  useEffect(() => {
    loadData(ContextUser);
  }, []);

  return (
    <ContextUser.Provider value={value}>
      {children}
    </ContextUser.Provider>
  );
};

export default ContextUser;
