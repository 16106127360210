import * as React from 'react';
import {translate} from "react-translate";
import {Header, Icon, Input, Segment} from 'semantic-ui-react';
import {ArrayField, TextArea} from "informed";

import {TextAreaH} from "../../components/informedHighlight";

const Others = ({edit, entry, newTerm, changed = [], t}) => (
  <>
    <Header as='h3' attached='top'>
      <Icon name='globe'/>{t('OTHERS')}
    </Header>

    {!edit ? (
      <Segment attached='bottom'>
        {entry.exlinks.exlink.map((e, i) => (
          <div key={'exl' + i}>
            <p>{i + 1}. <a target='_blank' rel='noopener noreferrer' href={e.$} style={{textDecoration: 'underline'}}>{e.$}</a></p>
          </div>
        ))}
      </Segment>
    ) : (
      <Segment attached='bottom' className='termForm-meaning'>
        {!newTerm && entry.exlinks && entry.exlinks.exlink.map((e, i) => (
          <div key={'exl' + i} className={(changed === null || (changed && changed[i] === null)) && 'changedDiff-NewBlock'}>
            <Input>{i + 1}. <div className="ui form">
              <TextAreaH field={'exl$' + i}/>
              {changed && changed[i] && <p className='changedDiff'>{changed[i].$}</p>}
            </div></Input>
          </div>
        ))}
        <ArrayField field='exlNew'>
          {({add, fields}) => (
            <>
              {fields.map((e, i) => (
                (i % 2 === 0) ? (
                  <div key={e.key}>
                    <Input>{((entry && entry.exlinks) ? entry.exlinks.exlink.length : 0) + (i / 2) + 1}.&nbsp;
                      <div className="ui form"><TextArea field={fields[i + 1].field} className={'hgltd'}/></div>
                    </Input>
                  </div>
                ) : <></>
              ))}

              <Icon onClick={() => { add(); add(); }} name='add circle' className='termForm-addCircle'/>
            </>
          )}
        </ArrayField>
      </Segment>
    )}
  </>
);

export default translate('Term')(Others);
